import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ServiceConfig } from "../utils/backend";

const useServiceConfig = (): ServiceConfig => {
  const { keycloak } = useKeycloak();
  const { entityId } = useParams<"entityId">();
  const { issueId: issueIdStr } = useParams<"issueId">();

  const serviceConfig = useMemo(
    () => ({
      entityId: entityId,
      issueId: issueIdStr ? Number(issueIdStr) : undefined,
      token: keycloak?.token,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
      qcServiceApiBaseUrl: process.env.REACT_APP_QC_SERVICE_API_BASE_URL || "",
    }),
    [entityId, issueIdStr, keycloak?.token]
  );

  return serviceConfig;
};

export default useServiceConfig;
