import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { NxpAuthSpinner } from "@nexploretechnology/nxp-ui";
import { Modal } from "antd";
import { camelCase, capitalize } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { File } from "../../services/app";
import {
  createIssueDocuments,
  deleteIssueDocument,
  updateIssueDocuments,
} from "../../services/document";
import {
  closeIssue,
  craetedIssueAttachment,
  craetedIssueNotification,
  deleteIssueAttachment,
  findOneIssue,
  Issue,
  IssueDocuments,
} from "../../services/issue";
import { NotificationForm } from "../../services/notification";
import { notify } from "@nexploretechnology/nxp-ui";
import { Action } from "../TasksListing/TaskReview/TaskDetailModal";
import IssueDetailsLayout from "./IssuesDetailsLayout";

interface IssueDetailsContainerProps {}
export type IssueTab =
  | "actions"
  | "implications"
  | "relatedDoc"
  | "notification"
  | "attachment";
interface IssueDetailsContextType {
  issue: Issue;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  onIssueCloseOutSubmit: () => void;
  onDocumentEditSubmit: (form: any) => Promise<boolean>;
  onFetchingIssue: () => void;
  onDocumentCreated: (form: any) => Promise<boolean>;
  onDocumentDeleted: (form: any) => Promise<boolean>;
  onDocumentUpdated: (form: any) => Promise<boolean>;
  onAttachmentCreated: (form: any) => Promise<boolean>;
  onAttachmentDeleted: (form: any) => Promise<boolean>;
  onNotificationCreated: (form: any) => Promise<boolean>;
  loading: boolean;
  onProgessing: (status: boolean) => void;
  onIssueImplicationSubmit: () => void;
}

export const IssueDetailsContext = React.createContext<IssueDetailsContextType>(
  {} as IssueDetailsContextType
);

const IssueDetailsContainer: React.FC<IssueDetailsContainerProps> = () => {
  const appContext = useAppContext();
  const { routeParams, serviceConfig } = appContext;
  const { issueId, entityId } = routeParams;

  const { t: translation } = useTranslation();

  const [issue, setIssue] = useState<Issue | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<IssueTab | string>(
    "relatedDoc" as IssueTab
  );

  const handleFetchingIssue = useCallback(async () => {
    try {
      const resIssue = await findOneIssue(entityId, issueId, serviceConfig);
      setIssue(resIssue);
    } catch (error: any) {
      notify.error(error);
    }
  }, [issueId, entityId, serviceConfig]);
  useEffect(() => {
    if (issueId) {
      handleFetchingIssue();
    }
  }, [entityId, issueId, serviceConfig, handleFetchingIssue]);

  const confirmAction = (type: Action) => {
    return new Promise((reslove) =>
      reslove(
        Modal.confirm({
          title: `${translation(
            `app.common.${
              camelCase(type) === "closed" ? "close" : camelCase(type)
            }`
          )} ${translation("app.common.issue")}?`,
          icon: <ExclamationCircleOutlined />,
          okText: translation("app.common.confirmLabel"),
          cancelText: translation("app.common.cancel"),
          content: `${capitalize(
            translation("issue.detail.action")
          )} ${translation("app.common.confirm.action.warning")}`,
          onOk: async () => {
            try {
              await closeIssue(entityId, issueId, serviceConfig);
              notify.success(translation("issue.closure.success"));
              handleFetchingIssue();
              reslove(true);
            } catch (error: any) {
              notify.error(error);
              reslove(false);
            }
          },
          onCancel: () => {
            reslove(false);
          },
        })
      )
    );
  };

  const handleCloseOutSubmit = () => {
    confirmAction("closed");
  };
  const handleDocumentEditSubmit = async (
    form: IssueDocuments
  ): Promise<boolean> => {
    try {
      await updateIssueDocuments(
        entityId,
        issueId,
        form?.id,
        form,
        serviceConfig
      );
      handleFetchingIssue();
      notify.success(translation("issueDetails.handleDocument.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  const handleIssueDocumentCreated = async (
    form: IssueDocuments
  ): Promise<boolean> => {
    try {
      await createIssueDocuments(entityId, issueId, form, serviceConfig);
      handleFetchingIssue();
      notify.success(translation("issueDetails.handleDocument.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  const handleIssueNotificationCreated = async (
    form: NotificationForm
  ): Promise<boolean> => {
    try {
      await craetedIssueNotification(entityId, issueId, form, serviceConfig);
      handleFetchingIssue();
      notify.success(translation("issueDetails.handleNotification.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  const handleDocumentUpdated = async (
    form: IssueDocuments
  ): Promise<boolean> => {
    try {
      await updateIssueDocuments(
        entityId,
        issueId,
        form?.id,
        { title: form?.title, documentNumber: form?.documentNumber },
        serviceConfig
      );
      handleFetchingIssue();
      notify.success(translation("issueDetails.handleDocument.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  const handleDocumentDeleted = async (
    form: IssueDocuments
  ): Promise<boolean> => {
    try {
      let postRes = await deleteIssueDocument(
        entityId,
        issueId,
        form?.id,
        serviceConfig
      );
      if (postRes) handleFetchingIssue();
      notify.success(translation("issueDetails.handleDocument.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  const handleAttachmentCreated = async (form: File[]): Promise<boolean> => {
    try {
      let postRes = await craetedIssueAttachment(
        entityId,
        issueId,
        form.map((file) => file.id),
        serviceConfig
      );
      if (postRes) handleFetchingIssue();
      notify.success(
        translation("issueDetails.handleAttachmentCreated.success")
      );
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleAttachmentDeleted = async (form: number[]): Promise<boolean> => {
    try {
      let postRes = await deleteIssueAttachment(
        entityId,
        issueId,
        form.join(","),
        serviceConfig
      );
      if (postRes) handleFetchingIssue();
      notify.success(
        translation("issueDetails.handleAttachmentDeleted.success")
      );
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleTaskImplicationSubmit = () => {
    if (issueId) handleFetchingIssue();
  };

  if (!issue) {
    return <NxpAuthSpinner />;
  }
  return (
    <IssueDetailsContext.Provider
      value={{
        issue,
        activeTab,
        setActiveTab,
        onFetchingIssue: handleFetchingIssue,
        onIssueImplicationSubmit: handleTaskImplicationSubmit,
        onIssueCloseOutSubmit: handleCloseOutSubmit,
        onDocumentEditSubmit: handleDocumentEditSubmit,
        onDocumentDeleted: handleDocumentDeleted,
        onDocumentUpdated: handleDocumentUpdated,
        onDocumentCreated: handleIssueDocumentCreated,
        onAttachmentCreated: handleAttachmentCreated,
        onAttachmentDeleted: handleAttachmentDeleted,
        onNotificationCreated: handleIssueNotificationCreated,
        loading: loading,
        onProgessing: setLoading,
      }}
    >
      <IssueDetailsLayout issue={issue} />
    </IssueDetailsContext.Provider>
  );
};

export default IssueDetailsContainer;
