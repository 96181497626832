import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpPanelEditable,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import {
  ImplicationForm,
  updateIssueImplication,
} from "../../services/implication";
import { Issue } from "../../services/issue";
import CustomApiError from "../../utils/backend/customApiError";
import { notify } from "@nexploretechnology/nxp-ui";

interface Props {
  handleIssueImplicationSave: () => void;
  implication: ImplicationForm;
  status?: Issue["status"];
}
const formSchema = yup.object({
  cost: yup.string(),
  reputation: yup.string(),
  time: yup.string(),
  commercial: yup.string(),
  implicationDescription: yup.string(),
});
const AppIssueImplication: React.FC<Props> = ({
  implication,
  handleIssueImplicationSave,
  status = "open",
}) => {
  const AppContext = useAppContext();
  const { hasRight, routeParams, serviceConfig } = AppContext;
  const originState = useMemo(() => implication, [implication]);
  const { t: translation } = useTranslation();
  const [userForm, setUserForm] = useState<ImplicationForm>(implication);
  const handleSaveValidated = async () => {
    if (routeParams?.entityId && routeParams?.issueId) {
      try {
        const implication = await updateIssueImplication(
          routeParams?.entityId,
          routeParams?.issueId,
          userForm,
          serviceConfig
        );
        if (implication) {
          notify.success(translation("Issue.implicationUpdate.success"));
          handleIssueImplicationSave();
        }
      } catch (e: any) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };
  const [validationError, , , saveWithValidate] = useYupValidate<
    Partial<ImplicationForm>
  >(userForm, formSchema, handleSaveValidated);
  const handlePanelSave = (setEditing: (editing: boolean) => void) => {
    if (handleSave()) {
      setEditing(false);
    }
  };

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };
  const formItems: NxpFormGridItemProps<ImplicationForm>[] = [
    {
      controlType: "input",
      label: translation("issue.implication.cost"),
      itemFieldName: "cost",
      span: 6,
    },
    {
      controlType: "input",
      label: translation("issue.implication.reputation"),
      itemFieldName: "reputation",
      span: 6,
    },
    {
      controlType: "input",
      label: translation("issue.implication.time"),
      itemFieldName: "time",
      span: 6,
    },

    {
      controlType: "input",
      label: translation("issue.implication.commercial"),
      itemFieldName: "commercial",
      span: 6,
    },
    {
      controlType: "input",
      label: translation("issue.implication.description"),
      itemFieldName: "implicationDescription",
      startOnNewRow: true,
      span: 24,
    },
  ];

  const handleFormGridStateChange = (
    fieldName: keyof ImplicationForm,
    value: unknown
  ) => {
    setUserForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  // if (!tasks) return null;
  return (
    <NxpPanelEditable
      titleContent={translation("app.common.details")}
      editable={hasRight("issue-manage") && status !== "closed"}
      defaultCollapsed={false}
      onPanelSave={handlePanelSave}
      onPanelCancel={() => setUserForm(originState)}
      disableCollapse={true}
    >
      {(editing, updateEditing) => {
        console.log("change", editing);
        return (
          <NxpFormGrid
            editing={editing}
            validationError={validationError}
            formItems={formItems}
            formState={userForm}
            onFormStateChange={handleFormGridStateChange}
          />
        );
      }}
    </NxpPanelEditable>
  );
};

export default AppIssueImplication;
