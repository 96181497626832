import { ServiceConfig, apiRequest } from "../utils/backend";
import buildQueryString from "../utils/backend/buildQueryString";
import { Creator, File } from "./app";
import { IssuesCount } from "./issue";

export interface TaskResponse {
  comment: string;
  createTime: string;
  creator: Creator;
  entityId: string;
  documents: File[];
  id: number;
}
export interface Task {
  id: number;
  entityId: string;
  subject: string;
  assignee: Pick<Creator, "email" | "name" | "entityId" | "id">;
  attachments: File[];
  openDate: string;
  targetDate: string;
  needDocument: boolean;
  status: "open" | "marked-closed" | "closed" | "overdue";
  documentType?: string;
  description?: string;
  responses: TaskResponse[];
  creator: Creator;
  issue: {
    id: number;
    entityId: string;
    code: string;
    status: string;
    subject: string;
    description: string;
    openDate: string;
    targetDate: string;
    createTime: TimeRanges;
    closeTime: string;
    cost: string;
    reputation: string;
    time: TimeRanges;
    commercial: string;
    implicationDescription: string;
    totalTaskCount: number;
    closedTaskCount: number;
  };
}
export interface TaskCreateForm {
  subject: string;
  assignee: Task["assignee"];
  openDate: string;
  targetDate: string;
  needDocument: boolean;
  documentType?: string;
  description: string;
}
export interface CreatedTaskFrom {
  comment: string;
  documents?: File[];
}
export interface TaskResponseFrom {
  comment: string;
  documents: File[];
}
export interface TasksCount extends IssuesCount {}
export const getTasksCount = async (
  entityId: string | undefined,
  issuseId: number | undefined,
  assigneeId: string | undefined,
  serviceConfig: ServiceConfig
): Promise<TasksCount> => {
  let paramtPath: {
    assigneeId?: string;
    issueId?: number;
  } = {};
  if (assigneeId) {
    paramtPath.assigneeId = assigneeId;
  }
  if (issuseId) {
    paramtPath.issueId = issuseId;
  }
  return await apiRequest<any>(
    `/entities/${entityId}/tasks/count?${buildQueryString(paramtPath)}`,
    serviceConfig
  );
};
export const getTasks = async (
  entityId: string | undefined,
  queryString: string | undefined,
  status: keyof IssuesCount,
  sortBy: string | undefined,
  order: "asc" | "desc",
  keyword: string | undefined,
  issueId: number | undefined,
  assigneeId: string | undefined,
  offset: number,
  limit: number,
  serviceConfig: ServiceConfig
): Promise<Task[]> => {
  if (keyword) {
    queryString += `&keyword=${keyword}`;
  }
  if (issueId) {
    queryString += `&issueId=${issueId}`;
  }
  if (assigneeId) {
    queryString += `&assigneeId=${assigneeId}`;
  }
  if (queryString) {
    queryString += `&${
      status === "all" ? "" : "status=" + status
    }&sortBy=${sortBy}&sortDirection=${order}&offset=${offset}&size=${limit}`;
  } else {
    queryString = `?${
      status === "all" ? "" : "status=" + status
    }&sortBy=${sortBy}&sortDirection=${order}&offset=${offset}&size=${limit}`;
  }

  return await apiRequest<Task[]>(
    `/entities/${entityId}/tasks${queryString || ""}`,
    serviceConfig
  );
};
export const getOneTask = async (
  entityId: string,
  taskId: number | undefined,
  serviceConfig: ServiceConfig
): Promise<Task> => {
  return await apiRequest<any>(
    `/entities/${entityId}/tasks/${taskId}`,
    serviceConfig
  );
};

export const createTask = async (
  entityId: string | undefined,
  issueId: number | undefined,
  taskForm: Partial<TaskCreateForm> | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issueId}/tasks`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(taskForm),
    }
  );
};
export const createTaskResponse = async (
  entityId: string | undefined,
  issuseId: number | undefined,
  taskId: number | undefined,
  taskResponse: Partial<TaskResponseFrom>,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issuseId}/tasks/${taskId}/response`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(taskResponse),
    }
  );
};
export const closeTask = async (
  entityId: string | undefined,
  taskId: number | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/tasks/${taskId}/close`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};
export const rejectTask = async (
  entityId: string | undefined,
  taskId: number | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/tasks/${taskId}/reject`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};
export const markCloseTask = async (
  entityId: string | undefined,
  taskId: number | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/tasks/${taskId}/mark-closed`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};
