import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Issue, IssueDocuments } from "../../../services/issue";
import AddDocumentModal from "./";

interface Props {
  onDocumentCreated: (form: Partial<IssueDocuments>) => Promise<boolean>;
  status?: Issue["status"];
}

const AddIssueDocumentButton: React.FC<Props> = ({
  onDocumentCreated,
  status = "open",
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={status === "closed"}
      >
        {translation("issue.relatedDocument.addDocument")}
      </NxpButton>
      <AddDocumentModal
        showModal={modalVisible}
        handleDialogClose={() => setModalVisible(false)}
        onIssueDocumentCreated={onDocumentCreated}
      />
    </>
  );
};

export default AddIssueDocumentButton;
