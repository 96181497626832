import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { LoadingOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Spin } from "antd";
import Dragger from "antd/lib/upload/Dragger";

import useAppContext from "../../hooks/useAppContext";
import { File } from "../../services/app";
import { notify } from "@nexploretechnology/nxp-ui";

interface Props {
  onFileUploaded?: (file: File) => void;
  onProgessing?: (status: boolean) => void;
  disabled?: boolean;
  limit?: number;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    border: "1px solid rgba(234, 237, 243, 1)",
    borderRadius: theme.borderRadius.main,
    backgroundColor: "rgba(251, 251, 253, 1)",
    padding: theme.spacing(1),
  },
  draggerContainer: {
    height: 160,
    width: "100%",

    "& .ant-upload, .ant-upload-drag": {
      border: "none",
    },
    "& .ant-btn": {
      marginLeft: 0,
    },
    "& .ant-upload-list": {
      display: "none",
    },
  },
  caption: {
    color: theme.palette.primary,
    fontWeight: theme.fontWeight.bolder,
    textTransform: "uppercase",
  },
  or: {
    color: theme.palette.text,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontSize.small,
    textTransform: "uppercase",
  },
  fileList: {
    width: 150,
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
  },
  fileListItem: {
    marginTop: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const AppUploader: React.FC<Props> = ({
  onFileUploaded = () => {},
  onProgessing = () => false,
  disabled = false,
  limit,
}) => {
  const appContext = useAppContext();
  const { routeParams, serviceConfig } = appContext;
  const { entityId } = routeParams;
  const classes = useStyles();

  const { t: translation } = useTranslation();

  const [uploadings, setUploadings] = useState<string[]>([] as string[]);

  return (
    <div className={classes.root}>
      <div className={classes.draggerContainer}>
        <Dragger
          data-testid="fileDropzone"
          name={"file"}
          multiple={true}
          accept="*"
          disabled={disabled}
          headers={{
            Authorization: `Bearer ${serviceConfig.token}`,
          }}
          action={`${serviceConfig.apiBaseUrl}/entities/${entityId}/files`}
          beforeUpload={(_, fileList) => {
            if (limit && fileList.length > limit) {
              notify.warning(translation("issue.attachment.warning"), {
                duration: 500,
              });
              return Promise.resolve(false);
            } else {
              return Promise.resolve(true);
            }
          }}
          onChange={(info) => {
            onProgessing(
              info.fileList.filter((file) => file.status === "uploading")
                .length > 0
            );
            const { status } = info.file;
            if (status !== "uploading") {
              // console.log(info.fileList);
            }

            if (status === "done") {
              onFileUploaded(info.file.response as File);
            } else if (status === "error") {
              notify.error(
                `${info.file.name} ${translation("appUploader.uploadFailed")}`
              );
            }
            setUploadings(
              info.fileList
                .filter((f) => f.status === "uploading")
                .map((f) => f.name)
            );
          }}
          onDrop={(event) => {
            // console.log("Dropped files", event.dataTransfer.files);
          }}
        >
          <p className={classes.caption}>
            {" "}
            {translation("appUploader.dragFilesToUpload")}
          </p>
          <p className={classes.or}> {translation("appUploader.or")}</p>
          <NxpButton type="primary" disabled={disabled}>
            {translation("appUploader.browseFile")}
          </NxpButton>
        </Dragger>
      </div>
      <div className={classes.fileList}>
        {uploadings.map((uploading, index) => (
          <p key={`upload_${index}`} className={classes.fileListItem}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, marginRight: 10 }}
                  spin
                />
              }
            />
            {uploading}
          </p>
        ))}
      </div>
    </div>
  );
};

export default AppUploader;
