import { notify } from "@nexploretechnology/nxp-ui";

export const useErrorHandler = () => useErrorHandlerFunc;

const useErrorHandlerFunc = (
  error: Error | unknown,
  taskDescription: string, // task in format such as 'getting locations', 'updating photo detail', 'deleting album'
  errorReference?: string | number | object, // information that could be useful for tracing error
  hideNotification?: boolean
) => {
  // const currentUser = useCurrentUser();
  // const location = useLocation()
  // should add logic for logging error here...

  if (!hideNotification) {
    notify.error(
      `Error occurred${taskDescription ? ` when ${taskDescription}` : ""} - ${
        error instanceof Error ? error.message : error
      }`
    );
  }
};

export default useErrorHandler;
