import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";

import AppFakeTableHeader from "../../components/AppFakeTableHeader";
import useAppContext from "../../hooks/useAppContext";
import { Creator } from "../../services/app";
import { Issue } from "../../services/issue";
import { CreatedTypeConfigForm, IssueType } from "../../services/typeConfig";
import AddTypeModal from "./AddTypeModal";
import TypeAssigneeList from "./TypeAssigneeList";

export interface AppIssueListItem extends Issue {
  creator: Creator;
}

interface LayoutProps {
  userIds: string[][];
  originalUserIds: string[][];
  currenType: IssueType[];
  onCoordinatorChange: (type: number, userIds: string[]) => void;
  onDelete: (typeId: number) => Promise<boolean>;
  onAdd: (form: CreatedTypeConfigForm) => Promise<boolean>;
  onEdit: (typeId: number, form: CreatedTypeConfigForm) => Promise<boolean>;
}
const useStyles = createUseStyles((theme) => ({
  content: { minHeight: theme.pxToRem(300) },
}));
const NotificationSetupLayout: React.FC<LayoutProps> = ({
  onAdd,
  onEdit,
  onDelete,
  onCoordinatorChange,
  userIds,
  originalUserIds,
}) => {
  const { t: translation } = useTranslation();
  const { hasRight, issueType } = useAppContext();
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <>
      <NxpHeader
        titleContent={`${translation("typeConfig.title")} `}
        actionContent={
          <>
            {hasRight("issue-type-create") && (
              <>
                <NxpButton
                  color="primary"
                  onClick={() => setShowAddRoleModal(true)}
                >
                  {translation("typeConfig.addType.btn")}
                </NxpButton>
                <AddTypeModal
                  show={showAddRoleModal}
                  onAdd={onAdd}
                  onClose={() => setShowAddRoleModal(false)}
                />
              </>
            )}
          </>
        }
      />
      <div className={classes.content}>
        <AppFakeTableHeader
          items={[
            { name: translation("app.common.type"), span: 5 },
            { name: translation("typeConfig.label.typeCoordinator"), span: 17 },
          ]}
          padding={24}
        />
        {issueType &&
          issueType.map((type, index) => (
            <TypeAssigneeList
              key={`${translation("app.common.type")}AssigneeList_${type.id}`}
              type={type}
              userIds={userIds[index]}
              originalUserIds={originalUserIds[index]}
              onCoordinatorsChange={onCoordinatorChange}
              onEditRole={async (
                typeId: number,
                form: CreatedTypeConfigForm
              ): Promise<boolean> => {
                return await onEdit(typeId, form);
              }}
              onDelete={onDelete}
            />
          ))}
      </div>
    </>
  );
};

export default NotificationSetupLayout;
