import { Col, Modal, Row, Upload } from "antd";
import clsx from "clsx";
import { camelCase, capitalize, get } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  formatDate,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { Creator } from "../../../services/app";
import { Issue } from "../../../services/issue";
import { Task, TaskCreateForm } from "../../../services/task";
import AddTaskResponse from "../AddTaskRespose";
import { TaskDetailsContext } from "../TaskListing";

interface Props {
  taskId: number;
  show: boolean;
  onClose: (taskId: number, status: string) => void;
}
type DetailForm = TaskCreateForm & {
  status: string;
  creator: Creator;
};
export type Action = "closed" | "reject" | "marked-closed";
const useStyles = createUseStyles((theme) => ({
  responselist: {
    padding: theme.spacing(2, 0),
    "& .title": {
      fontSize: theme.pxToRem(14),
      fontWeight: theme.fontWeight.bolder,
    },
    "& .discription": {
      marginBottom: theme.spacing(1),
      fontSize: theme.pxToRem(14),
      lineHeight: theme.pxToRem(22),
    },
  },
  inputLable: {
    "& .ant-form-item": {
      marginBottom: theme.spacing(0.4),
    },
    "& label": {
      color: theme.palette.textLabel,
      fontSize: theme.pxToRem(12),
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: theme.spacing(0),
      "& ::after": {
        content: "",
      },
    },
  },
}));

const TaskDetailModal: React.FC<Props> = ({ show, onClose, taskId }) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  const [form, setForm] = useState<DetailForm>();
  const [, setSaving] = useState<boolean>(false);
  const { hasRight, activeUser } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [issueOwner, setIssueOwner] = useState<Issue["creator"]>();
  const {
    onFetchOneTask,
    onTaskRejected,
    onTaskMarkClosed,
    onTaskClosed,
    taskResponse,
    setTaskResponses,
  } = useContext(TaskDetailsContext);
  const fetchTask = useCallback(() => {
    onFetchOneTask(taskId).then((task) => {
      if (task) {
        const taskRes = task as Task;
        setTaskResponses(taskRes?.responses);
        setIssueOwner(taskRes?.creator);
        setForm({
          subject: taskRes?.subject,
          assignee: taskRes?.assignee,
          status: taskRes?.status,
          needDocument: taskRes?.needDocument,
          documentType: taskRes?.documentType,
          openDate: taskRes?.openDate,
          targetDate: taskRes?.targetDate,
          description: taskRes?.description,
          creator: taskRes?.creator,
        } as DetailForm);
      }
    });
  }, [onFetchOneTask, setTaskResponses, taskId]);
  useEffect(() => {
    fetchTask();
    return () => {
      setSaving(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const formItems: NxpFormGridItemProps<DetailForm>[] = [
    {
      controlType: "input",
      label: translation("task.subject"),
      itemFieldName: "subject",
      span: 8,
    },
    {
      controlType: "input",
      label: translation("task.assigned"),
      itemFieldName: "assignee",
      controlProps: {
        value: form?.assignee?.name,
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("task.status"),
      itemFieldName: "status",
      span: 8,
    },
    {
      controlType: "checkbox",
      label: translation("task.closeOutDoc"),
      itemFieldName: "needDocument",
      span: 8,
    },
    {
      controlType: "input",
      label: translation("task.docTitile"),
      itemFieldName: "documentType",
      span: 8,
    },
    {
      controlType: "datePicker",
      label: translation("task.opendate"),
      itemFieldName: "openDate",
      span: 8,
    },
    {
      controlType: "textarea",
      label: translation("task.description"),
      itemFieldName: "description",
      span: 16,
    },
    {
      controlType: "datePicker",
      label: translation("task.endDate"),
      itemFieldName: "targetDate",
      span: 8,
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
  };

  const [validationError] = useYupValidate<DetailForm>(
    form,
    handleSaveValidated
  );

  const handleCloseButton = () => {
    onClose(taskId, form?.status ?? "");
  };
  const confirmAction = (type: Action) => {
    console.log("confirmAction type: ", type);
    Modal.confirm({
      title: ` ${translation(
        `app.common.${camelCase(type) === "closed" ? "close" : camelCase(type)}`
      )} ${translation("task.subject")} ?`,
      icon: <ExclamationCircleOutlined />,
      okText: translation("app.common.confirmLabel"),
      cancelText: translation("app.common.cancel"),
      content: `${capitalize(translation("issue.detail.action"))} ${translation(
        "app.common.confirm.action.warning"
      )}`,
      onOk: async () => {
        switch (type) {
          case "closed":
            await onTaskClosed(taskId);
            break;
          case "marked-closed":
            await onTaskMarkClosed(taskId);
            break;
          case "reject":
            await onTaskRejected(taskId);
            break;
        }
        fetchTask();
      },
      onCancel: () => {},
    });
  };

  return (
    <NxpModal
      width="large"
      title={translation("task.detail.title")}
      visible={show}
      showMandatoryLabel={form?.status !== "closed"}
      onCancel={handleCloseButton}
      footer={
        !loading && (
          <>
            {taskId &&
              hasRight("issue-manage") &&
              form?.status !== "closed" && (
                <>
                  {form?.creator?.id === activeUser?.id && (
                    <NxpButton
                      onClick={() => confirmAction("reject")}
                      danger
                      type="text"
                      disabled={form?.status !== "marked-closed"}
                    >
                      {translation("task.reject")}
                    </NxpButton>
                  )}
                </>
              )}
            {taskId &&
              hasRight("issue-manage") &&
              form?.creator?.id === activeUser?.id && (
                <NxpButton
                  onClick={() => confirmAction("closed")}
                  disabled={
                    form?.status !== "marked-closed" &&
                    form?.status === "closed"
                  }
                >
                  {translation("task.close")}
                </NxpButton>
              )}
            {taskId &&
              hasRight("task-read") &&
              form?.status !== "closed" &&
              form?.status !== "marked-closed" && (
                <NxpButton
                  disabled={form?.assignee?.id !== activeUser?.id}
                  onClick={() => confirmAction("marked-closed")}
                >
                  {translation("task.markClose")}
                </NxpButton>
              )}
          </>
        )
      }
    >
      <NxpFormGrid
        editing={false}
        validationError={validationError}
        formItems={formItems}
        formState={form}
      />
      {form?.status === ("open" as Task["status"]) && (
        <AddTaskResponse setAttactmentLoading={setLoading} />
      )}
      {form?.status === ("overdue" as Task["status"]) && (
        <AddTaskResponse setAttactmentLoading={setLoading} />
      )}

      {form?.status === ("marked-closed" as Task["status"]) &&
        issueOwner?.id === activeUser?.id && (
          <AddTaskResponse setAttactmentLoading={setLoading} />
        )}
      {taskResponse.length > 0 && (
        <div className={classes.responselist}>
          <div
            className={clsx(classes.inputLable, "ant-col ant-form-item-label")}
          >
            <div className="ant-form-item">
              <label title="Attachment">
                {translation("task.response.history", {
                  count: taskResponse.length,
                })}
              </label>
            </div>
          </div>
          {taskResponse.map((res) => (
            <Row key={res?.id}>
              <Col>
                <div className="title">
                  {`${get(res, "creator.name")} on ${formatDate(
                    moment(Number(res?.createTime)).toDate()
                  )}`}
                </div>
                <div className="discription">{`${get(res, "comment")}`}</div>
                <Row>
                  {res?.documents.map((file) => (
                    <Col key={file?.id}>
                      <>
                        <Upload
                          listType="picture-card"
                          openFileDialogOnClick={true}
                          disabled={true}
                          defaultFileList={[
                            {
                              uid: file?.id.toString(),
                              name: file.name,
                              status: "success",
                              response: file.name, // custom error message to show
                              url: file?.originalUrl,
                            },
                          ]}
                        ></Upload>
                      </>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </NxpModal>
  );
};

export default TaskDetailModal;
