import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";

import { SearchOutlined } from "@ant-design/icons";
import {
  NxpFormItem,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

import AppIssueList from "../../components/AppIssuesList";
import useAppContext from "../../hooks/useAppContext";
import { getIssuesCount, IssuesCount } from "../../services/issue";
import AddIssueButton from "./AddIssue/AddIssueButton";

interface Props {}

const IssueListing: React.FC<Props> = (props) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, hasRight } = appContext;
  const { entityId } = routeParams;
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const searchPhase = useLocation()?.search;
  const [invalidateAt, setInvalidateAt] = useState<number>(0);
  const [extraQueries] = useState<string[]>([]);
  const [, setSortBy] = useState<undefined | string>("no"); // for reference only, AppDefectList handle sorting on it's own
  const [, setOrder] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
  const [activeTab, setActiveTab] = useState<string>("all");
  const [issuesCount, setIssuesCount] = useState<IssuesCount>();
  const [keyword, setKeyword] = useState<string>("");

  const handleIssueCreated = () => {
    setInvalidateAt(new Date().getTime());
    getCount();
  };

  let queryString = [searchPhase, ...extraQueries].join("&");

  if (!queryString.startsWith("?")) queryString = `?${queryString}`;

  const handleSortingChange = (sortBy: string, order: "asc" | "desc") => {
    setSortBy(sortBy);
    setOrder(order);
  };
  async function getCount() {
    if (entityId) {
      const count = await getIssuesCount(entityId, keyword, serviceConfig);
      if (!count) {
        return;
      }
      setIssuesCount(count);
    }
  }

  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  let title = translation("issue.title");
  return (
    <>
      <NxpHeader
        titleContent={title}
        actionContent={
          <>
            <Row gutter={10}>
              {hasRight("issue-read") && (
                <Col>
                  <NxpFormItem
                    controlType={"input"}
                    controlProps={{
                      allowClear: true,
                      value: keyword,
                      onChange: (e) => setKeyword(e.target.value),
                      prefix: <SearchOutlined />,
                      placeholder: translation("app.common.search"),
                    }}
                  />
                </Col>
              )}
              {hasRight("issue-manage") && (
                <Col>
                  <AddIssueButton onIssueCreated={handleIssueCreated} />
                </Col>
              )}
            </Row>
          </>
        }
      />
      {hasRight("issue-read") && (
        <>
          <NxpTabs
            defaultActiveKey="all"
            onChange={(activeKey) => setActiveTab(activeKey)}
          >
            <TabPane
              tab={translation("issue.tab.all", { count: issuesCount?.all })}
              key={"all"}
              className="all"
            >
              {entityId && (
                <AppIssueList
                  keyword={keyword}
                  status={activeTab as keyof IssuesCount}
                  queryString={queryString}
                  invalidateAt={invalidateAt}
                  onIssueClick={(issueId: number) =>
                    navigate(`/entities/${entityId}/issues/${issueId}`)
                  }
                  onSortingChange={handleSortingChange}
                  tableProps={{
                    scroll: {
                      y: window.innerHeight - 300,
                    },
                  }}
                />
              )}
            </TabPane>
            <TabPane
              tab={translation("issue.tab.open", { count: issuesCount?.open })}
              key={"open"}
              className="open"
            >
              {entityId && (
                <AppIssueList
                  keyword={keyword}
                  status={activeTab as keyof IssuesCount}
                  queryString={queryString}
                  invalidateAt={invalidateAt}
                  onIssueClick={(issueId: number) =>
                    navigate(`/entities/${entityId}/issues/${issueId}`)
                  }
                  onSortingChange={handleSortingChange}
                  tableProps={{
                    scroll: {
                      y: window.innerHeight - 300,
                    },
                  }}
                />
              )}
            </TabPane>
            <TabPane
              tab={translation("issue.tab.overdue", {
                count: issuesCount?.overdue,
              })}
              key="overdue"
              className="open"
            >
              {entityId && (
                <AppIssueList
                  keyword={keyword}
                  status={activeTab as keyof IssuesCount}
                  queryString={queryString}
                  invalidateAt={invalidateAt}
                  onIssueClick={(issueId: number) =>
                    navigate(`/entities/${entityId}/issues/${issueId}`)
                  }
                  onSortingChange={handleSortingChange}
                  tableProps={{
                    scroll: {
                      y: window.innerHeight - 300,
                    },
                  }}
                />
              )}
            </TabPane>
            <TabPane
              tab={translation("issue.tab.closed", {
                count: issuesCount?.closed,
              })}
              key="closed"
              className="closed"
            >
              {entityId && (
                <AppIssueList
                  keyword={keyword}
                  status={activeTab as keyof IssuesCount}
                  queryString={queryString}
                  invalidateAt={invalidateAt}
                  onIssueClick={(issueId: number) =>
                    navigate(`/entities/${entityId}/issues/${issueId}`)
                  }
                  onSortingChange={handleSortingChange}
                  tableProps={{
                    scroll: {
                      y: window.innerHeight - 300,
                    },
                  }}
                />
              )}
            </TabPane>
          </NxpTabs>
        </>
      )}
    </>
  );
};

export default IssueListing;
