import React, { useCallback, useEffect, useRef, useState } from "react";

import { LazyLoadStatus, NxpTableProps } from "@nexploretechnology/nxp-ui";
import _ from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { getIssues, Issue, IssuesCount } from "../../services/issue";
import { LAZY_LOAD_PAGE_SIZE } from "../../utils/const";
import { notify } from "@nexploretechnology/nxp-ui";
import AppIssueListLayout, { AppIssueListItem } from "./AppIssuesListLayout";

interface Props {
  queryString?: string;
  invalidateAt?: number;
  onIssueClick?: (defectId: number) => void;
  onSortingChange?: (sortBy: string, order: "asc" | "desc") => void;
  tableProps?: NxpTableProps<any>;
  status: keyof IssuesCount;
  keyword: string;
}

const AppIssueListContainer: React.FC<Props> = ({
  queryString,
  invalidateAt = 0,
  onIssueClick = () => {},
  onSortingChange = () => {},
  tableProps = {},
  status = "all",
  keyword = "",
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams } = appContext;
  const { entityId, issueId } = routeParams;

  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [listItems, setListItems] = useState<null | AppIssueListItem[]>(null);

  const [sortBy, setSortBy] = useState<undefined | string>("code");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const queryStates = useRef<{
    queryString?: string;
    listItems: AppIssueListItem[] | null;
    sortBy: undefined | string;
    keyword?: string;
    order: "asc" | "desc";
    status: keyof IssuesCount;
  }>({
    queryString,
    listItems,
    sortBy,
    order,
    status,
    keyword,
  });

  queryStates.current = {
    queryString,
    listItems,
    sortBy,
    keyword,
    order,
    status,
  };

  const fetchData = useCallback(
    (limit: number, clear: boolean) => {
      setLazyLoadStatus(LazyLoadStatus.loading);
      getIssues(
        entityId,
        queryString,
        queryStates.current.status,
        queryStates.current.sortBy,
        queryStates.current.order,
        queryStates.current.keyword,
        clear
          ? 0
          : queryStates.current.listItems
          ? queryStates.current.listItems.length
          : 0,
        limit,
        serviceConfig
      )
        .then((issues: Issue[]) => {
          setLazyLoadStatus(LazyLoadStatus.ready);
          setListItems((listItems) => {
            return clear ? issues : (listItems || []).concat(issues);
          });
        })
        .catch((e) => notify.error(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, issueId]
  );

  useEffect(() => {
    fetchData(LAZY_LOAD_PAGE_SIZE, true);
  }, [sortBy, order, fetchData, invalidateAt, queryStates?.current?.status]);

  useEffect(() => {
    handleLazyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStates?.current?.keyword]);

  const handleSortingChange = (field: any, order: any) => {
    setSortBy(field);
    setOrder(order === "descend" ? "desc" : "asc");
    onSortingChange(field, order === "descend" ? "desc" : "asc");
  };

  const handleLazyLoad = _.debounce((clear = false) => {
    if (lazyLoadStatus === LazyLoadStatus.ready) {
      fetchData(LAZY_LOAD_PAGE_SIZE, clear);
    }
  }, 500);

  return (
    <AppIssueListLayout
      listItems={listItems}
      lazyLoadStatus={lazyLoadStatus}
      onLazyLoad={handleLazyLoad}
      onSortingChange={handleSortingChange}
      onRowClick={onIssueClick}
      tableProps={tableProps}
    />
  );
};

export default AppIssueListContainer;
