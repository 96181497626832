import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { IssueDocuments, IssueMessage } from "../../../services/issue";

interface AddIssueModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onIssueDocumentCreated: (form: Partial<IssueDocuments>) => Promise<boolean>;
}

const AddIssueContainer: React.FC<AddIssueModalProps> = ({
  showModal,
  handleDialogClose,
  onIssueDocumentCreated,
}) => {
  const [, setIssueAttachments] = useState<IssueMessage["attachments"]>([]);
  const appContext = useAppContext();
  const { routeParams } = appContext;
  const { entityId } = routeParams;

  useEffect(() => {
    return () => {
      setIssueAttachments([]);
    };
  }, [showModal]);
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    title: yup
      .string()
      .nullable()
      .required(translation("issue.relatedDocument.titleIsRequired")),
    documentNumber: yup
      .string()
      .nullable()
      .required(translation("issue.relatedDocument.documentNumberIsRequired")),
  });

  const [issueForm, setIssueForm] = useState<Partial<IssueDocuments>>({});
  useEffect(() => {
    return () => {
      setIssueForm({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, onIssueDocumentCreated]);

  const formItems: NxpFormGridItemProps<Partial<IssueDocuments>>[] = [
    {
      controlType: "input",
      label: translation("issue.relatedDocument.title"),
      itemFieldName: "title",
      required: true,
      span: 12,

      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
    {
      controlType: "input",
      label: translation("issue.relatedDocument.documentNumber"),
      itemFieldName: "documentNumber",
      required: true,
      span: 12,

      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
  ];

  const handleSaveValidated = async () => {
    if (entityId) {
      onIssueDocumentCreated(issueForm);
    }
  };

  const [validationError, , , saveWithValidate] = useYupValidate<
    Partial<IssueDocuments>
  >(issueForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof issueForm,
    value: unknown
  ) => {
    setIssueForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      handleDialogClose();
    }
  };
  return (
    <NxpModal
      width="medium"
      title={translation("issue.relatedDocument.addNewDocHeader")}
      visible={showModal}
      showMandatoryLabel
      onCancel={handleDialogClose}
      footer={
        <NxpButton onClick={handleModalSave}>
          {translation("app.common.save")}
        </NxpButton>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={issueForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddIssueContainer;
