import { Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import { DataNode, EventDataNode } from "antd/lib/tree";
import { find, get, head, isObject } from "lodash";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  notify,
  NxpFormItem,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { Media } from "../../services/app";
import { getIssueCategories } from "../../services/issue";
import {
  createNotification,
  getNotifications,
  Notification,
  updateNotification,
  UpdateNotificationForm,
} from "../../services/notification";
import { getIssueTypes, IssueType } from "../../services/typeConfig";
import NotificationSetupLayout from "./NotificationSetupLayout";

export type SeleteObjPros = {
  event: "select";
  selected: boolean;
  node: EventDataNode<any>;
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
};
interface NotificationSetupContainerProps {}
interface NotificationsContextType {
  notoification: Notification[];
  activeMediaTab: Media | string;
  setMediaTab: (tab: Media | string) => void;
  selectedTypeKey?: React.Key[];
  setSelectedTypeKey: (tab: React.Key[]) => void;
  loading: boolean;
  editItem: Item;
  saveInProgress: boolean;
  onRowEdit: (editItem: Item) => void;
  onRowSave: () => void;
  onRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  columns: ColumnProps<Item>[];
  dataSource: Item[][];
  selectedNotification: SeleteObjPros;
  setSelectedNotification: (selection: SeleteObjPros) => void;
}

export const NotificationsContext =
  React.createContext<NotificationsContextType>({} as NotificationsContextType);
export interface Item {
  key: string;
  name: string;
  channel: string;
  users: string[];
  issueCategory: number;
  issueType: number;
  databaseCreated: boolean;
  notificationId: number | null;
  media: Media;
}

const formSchema = yup.object().shape({
  name: yup.string(),
  channel: yup.string(),
  users: yup.array(),
});

const NotificationSetupContainer: React.FC<
  NotificationSetupContainerProps
> = () => {
  const [notifications] = useState<Notification[]>();
  const { issueType, routeParams, serviceConfig, users, hasRight } =
    useAppContext();
  const { entityId } = routeParams;
  const { t: translation } = useTranslation();
  const [mediaTab, setMediaTab] = useState<Media | string>("email");
  const [selectedTypeKey, setSelectedTypeKey] = useState<React.Key[]>([0]);
  const [dataSource, setDataSource] = useState<Item[][]>([]);
  const [, setNotificationsRecord] = useState<Notification[]>();
  const [selectedNotification, setSelectedNotification] =
    useState<SeleteObjPros>({} as SeleteObjPros);
  const [loading] = useState<boolean>(false);
  const getColumns = (
    editRecord: Item | undefined,
    setEditRecord: (callback: SetStateAction<Item | undefined>) => void,
    issueType: IssueType[]
  ): ColumnProps<Item>[] => [
    {
      title: translation("notification.issue"),
      dataIndex: "name",
      width: 150,
    },
    {
      title: translation("notification.channel"),
      dataIndex: "channel",
      width: 150,
    },
    {
      title: translation("notification.destination"),
      dataIndex: "users",
      width: 500,
      render: (_: unknown, record: Item) => {
        const editing = editRecord?.key === record.key;
        return (
          <>
            {users && (
              <NxpFormItem
                editing={editing}
                controlType="selectMultiple"
                controlProps={{
                  value: editing ? editRecord?.users : record.users,
                  allowClear: true,
                  disabled:
                    !hasRight("notification-delete") &&
                    !hasRight("notification-create"),
                  options: users.map((user: any) => {
                    return {
                      label: user.name,
                      value: user.id,
                    };
                  }),
                  onChange: ((value: string[]) => {
                    setEditRecord(
                      (prevState) => prevState && { ...prevState, users: value }
                    );
                  }) as any,
                }}
              />
            )}
          </>
        );
      },
    },
  ];
  const handleFetchNotifications = async (): Promise<boolean> => {
    try {
      let getRes = await getNotifications(entityId, serviceConfig);
      setNotificationsRecord(getRes);
      let issueType = await getIssueTypes(entityId, serviceConfig);
      let issueCategory = await getIssueCategories(entityId, serviceConfig);
      let ArrayDatasource: Item[][] = [];
      issueType.forEach((type, typeIndex) => {
        ArrayDatasource[typeIndex] = [];
        issueCategory.forEach((category, catIndex) => {
          const foundRecord = find(
            getRes,
            (record) =>
              get(record, "issueCategory.id") === category?.id &&
              get(record, "issueType.id") === type?.id &&
              record.media === "email"
          );
          ArrayDatasource[typeIndex].push({
            key: catIndex.toString(),
            name: `${category.name}`,
            channel: translation(`app.common.${mediaTab}`),
            users: isObject(foundRecord)
              ? foundRecord?.recipients.map((user) => user.id)
              : [],
            databaseCreated: isObject(foundRecord) ? true : false,
            notificationId: isObject(foundRecord) ? foundRecord?.id : null,
            issueCategory: category.id,
            issueType: type?.id,
            media: "email" as Media,
          });
        });
      });
      setDataSource(ArrayDatasource);
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };
  useEffect(() => {
    handleFetchNotifications().then(() => {
      if (issueType && issueType.length) {
        setSelectedTypeKey([0]);
        setSelectedNotification({
          node: { ...head(issueType), title: head(issueType)?.name },
        } as unknown as SeleteObjPros);
      }
    });
    return () => {
      setSelectedTypeKey([]);
      setSelectedNotification({} as SeleteObjPros);
      setNotificationsRecord([]);
      setDataSource([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // tab form
  const [editItem, setEditItem] = useState<Item>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      if (editItem?.databaseCreated && editItem?.notificationId) {
        await updateNotification(
          entityId,
          editItem?.notificationId,
          {
            media: editItem?.media,
            issueType: editItem?.issueType,
            issueCategory: editItem?.issueCategory,
            recipients: editItem?.users,
          },
          serviceConfig
        );
      } else {
        await createNotification(
          entityId,
          {
            media: editItem?.media,
            issueType: editItem?.issueType,
            issueCategory: editItem?.issueCategory,
            recipients: editItem?.users,
          } as UpdateNotificationForm,
          serviceConfig
        );
      }
      handleFetchNotifications();
      notify.actionCompleted();
    } catch (error: any) {
      notify.error(error);
    }
    setEditItem(undefined);
    setTimeout(() => setSaveInProgress(false));
  };
  useEffect(() => {
    return () => {
      setDataSource([]);
    };
  }, []);
  const [, , clearError, saveWithValidate] = useYupValidate<
    Pick<Item, "users">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => {
      return getColumns(editItem, setEditItem, issueType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, selectedTypeKey]
  );

  const handleRowEdit = useCallback(
    (editItem: Item) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );
  return (
    <>
      <Spin spinning={dataSource?.length <= 0}>
        <NotificationsContext.Provider
          value={{
            notoification: notifications ?? [],
            activeMediaTab: mediaTab,
            setMediaTab,
            selectedTypeKey,
            setSelectedTypeKey,
            loading,
            saveInProgress,
            editItem: editItem!,
            onRowCancel: handleRowCancel,
            onRowEdit: handleRowEdit,
            onRowSave: handleRowSave,
            columns,
            dataSource: dataSource,
            selectedNotification,
            setSelectedNotification,
          }}
        >
          <NotificationSetupLayout />
        </NotificationsContext.Provider>
      </Spin>
    </>
  );
};

export default NotificationSetupContainer;
