import { apiRequest, ServiceConfig } from "../utils/backend";

export interface ImplicationForm {
  cost: string;
  reputation: string;
  time: string;
  commercial: string;
  implicationDescription: string;
}
export const updateIssueImplication = async (
  entityId: string,
  issueId: number,
  implicationForm: Partial<ImplicationForm> | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issueId}/implications`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(implicationForm),
    }
  );
};
