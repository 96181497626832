import React from "react";
import { useTranslation } from "react-i18next";

import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

import AppFakeTableHeader from "../../components/AppFakeTableHeader";
import useAppContext from "../../hooks/useAppContext";
import { UserRightList } from "../../services/app";
import { Role } from "../../services/role";
import RightGroupPanel from "./RightGroupPanel";

interface Props {
  roles: Role[];
  selectedIndex: number;
  checklist: Record<keyof UserRightList, boolean>;
  onRoleSelect: (index: number) => void;
  onItemCheck: (values: string, checked: boolean) => void;
  onSaveRights: () => void;
}

const RightSettingLayout: React.FC<Props> = ({
  roles,
  selectedIndex,
  checklist,
  onRoleSelect,
  onItemCheck,
  onSaveRights,
}) => {
  const { hasRight } = useAppContext();

  const { t: translation } = useTranslation();

  const rightGroups: {
    title: string;
    hide?: boolean;
    items: { title: string; value: keyof UserRightList }[];
  }[] = [
    {
      title: translation("rightSetting.register.label"),
      items: [
        {
          title: translation("rightSetting.register.view"),
          value: "issue-read",
        },
        {
          title: translation("rightSetting.register.manage"),
          value: "issue-manage",
        },
      ],
    },
    {
      title: translation("app.common.type"),
      items: [
        {
          title: translation("rightSetting.type.create"),
          value: "issue-type-create",
        },
        {
          title: translation("rightSetting.type.view"),
          value: "issue-type-read",
        },
        {
          title: translation("rightSetting.type.edit"),
          value: "issue-type-update",
        },
        {
          title: translation("rightSetting.type.delete"),
          value: "issue-type-delete",
        },
        {
          title: translation("rightSetting.type.assign"),
          value: "issue-type-assign",
        },
      ],
    },
    {
      title: translation("issue.task"),
      items: [
        { title: translation("rightSetting.task.view"), value: "task-read" },
      ],
    },
    {
      title: translation("rightSetting.notification.title"),
      items: [
        {
          title: translation("rightSetting.notification.view"),
          value: "notification-read",
        },
        {
          title: translation("rightSetting.notification.create"),
          value: "notification-create",
        },
        {
          title: translation("rightSetting.notification.edit"),
          value: "notification-update",
        },
        {
          title: translation("rightSetting.notification.delete"),
          value: "notification-delete",
        },
      ],
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={`${translation("rightSetting.accessRights")}`}
        actionContent={
          selectedIndex !== -1 && (
            <Row gutter={10}>
              {hasRight("right-update") && (
                <Col>
                  <NxpButton color="primary" onClick={onSaveRights}>
                    {translation("app.common.save")}
                  </NxpButton>
                </Col>
              )}
            </Row>
          )
        }
      />
      <Row>
        <Col span={6}>
          {roles.map((role, index) => (
            <div key={`role_${role?.id}`}>
              <NxpButton
                type="link"
                style={
                  selectedIndex === index
                    ? {}
                    : { color: "rgba(62, 63, 66, 1)", fontWeight: "normal" }
                }
                onClick={() => onRoleSelect(index)}
              >
                {role.name}
              </NxpButton>
            </div>
          ))}
        </Col>
        <Col span={18}>
          {selectedIndex !== -1 && (
            <>
              <AppFakeTableHeader
                items={[
                  { name: " ", span: 1 },
                  { name: translation("rightSetting.rights"), span: 22 },
                  { name: translation("rightSetting.allow"), span: 1 },
                ]}
                padding={8}
              />
              {rightGroups
                .filter((rightGroup) => !rightGroup.hide)
                .map((rightGroup) => (
                  <RightGroupPanel
                    title={rightGroup.title}
                    items={
                      rightGroup.items as {
                        title: string;
                        value: keyof UserRightList;
                      }[]
                    }
                    checklist={checklist}
                    onItemCheck={onItemCheck}
                  />
                ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RightSettingLayout;
