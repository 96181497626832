import httpRequest from "./httpRequest";

export interface ServiceConfig {
  entityId?: string;
  registerId?: number; // deprecated
  issueId?: number;
  token?: string;
  apiBaseUrl: string;
  qcServiceApiBaseUrl: string;
}

export default async function apiRequest<R = any>(
  url: string,
  serviceConfig: ServiceConfig,
  options?: RequestInit,
  retryLimit = 0,
  lastRetryDelay = 2000
) {
  const requestSettings: RequestInit = {
    ...options,
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || "",
      ...options?.headers,
    },
  };

  return httpRequest<R>(
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : serviceConfig.apiBaseUrl + url,
    requestSettings,
    retryLimit,
    lastRetryDelay
  );
}
