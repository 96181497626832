import { apiRequest, ServiceConfig } from "../utils/backend";
import { Creator, File, Location, User } from "./app";
import { NotificationForm } from "./notification";
import { Task } from "./task";
import { IssueType } from "./typeConfig";

export interface IssueNotification {
  id: number;
  entityId: string;
  media: string;
  recipient: Creator;
  sendTime: string;
  template: string;
  createTime: string;
  context: {
    message: string;
    issueUrl: string;
    recipient: string;
    issueSubject: string;
  };
}
export interface IssueCategory {
  id: number;
  entityId: string;
  name: string;
  disabled: boolean;
}

export interface IssueDocuments {
  id: number;
  entityId: string;
  issue: string;
  title: string;
  documentNumber: string;
}
export interface Issue {
  id: number;
  status: "open" | "closed" | "overdue";
  code: string;
  issueCategory: IssueCategory;
  subject: string;
  description: string;
  issueType?: IssueType;
  location: Location;
  openDate: string;
  targetDate: string;
  createTime: number;
  cost: string;
  reputation: string;
  time: string;
  commercial: string;
  implicationDescription: string;
  issueDocuments: IssueDocuments[];
  attachments: File[];
  creator: Creator;
  tasks: Task[];
  totalTaskCount: number;
  closedTaskCount: number;
  notifications: IssueNotification[];
}

export interface IssueMessage {
  id: number;
  type: "inspection" | "response" | "closure" | "cancellation";
  createdBy: User;
  defectStatus: Issue["status"];
  date: string;
  nextInspectionDate: string;
  comment: string;
  attachments: File[];
}

export type IssueCreateForm = Pick<
  Issue,
  | "issueType"
  | "issueCategory"
  | "subject"
  | "openDate"
  | "targetDate"
  | "description"
> & {
  attachments: number[];
  location: number;
};
export type Sorting =
  | "code"
  | "issueType"
  | "issueCategory"
  | "subject"
  | "description"
  | "location"
  | "openDate"
  | "targetDate"
  | "status"
  | "creator"
  | "closeTime"
  | "closer";

export interface StatisticQuery
  extends Partial<{
    dueDateGte: Date;
    dueDateLte: Date;
    statusNot: string;
    status: string;
    responsiblePartyId: number;
    locationGroupId: number;
    locationId: number;
  }> {}
export interface IssuesCount {
  all: number;
  open: number;
  overdue: number;
  closed: number;
  "marked-closed": number;
}
export const getIssueTypes = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<IssueType[]> => {
  return await apiRequest(`/entities/${entityId}/issue-types`, serviceConfig);
};
export const getIssueCategories = async (
  entityId: string | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<IssueCategory[]>(
    `/entities/${entityId}/issue-categories`,
    serviceConfig
  );
};
export const getIssues = async (
  entityId: string | undefined,
  queryString: string | undefined,
  status: keyof IssuesCount,
  sortBy: string | undefined,
  order: "asc" | "desc",
  keyword: string | undefined,
  offset: number,
  limit: number,
  serviceConfig: ServiceConfig
): Promise<Issue[]> => {
  if (!sortBy) sortBy = "code";
  if (keyword) {
    queryString += `&keyword=${keyword}`;
  }
  if (queryString) {
    queryString += `&${
      status === "all" ? "" : "status=" + status
    }&sortBy=${sortBy}&sortDirection=${order}&offset=${offset}&size=${limit}`;
  } else {
    queryString = `?${
      status === "all" ? "" : "status=" + status
    }&sortBy=${sortBy}&sortDirection=${order}&offset=${offset}&size=${limit}`;
  }

  return await apiRequest<Issue[]>(
    `/entities/${entityId}/issues${queryString || ""}`,
    serviceConfig
  );
};
export const getIssuesCount = async (
  entityId: string,
  keyword: string | undefined,
  serviceConfig: ServiceConfig
): Promise<IssuesCount> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/count`,
    serviceConfig
  );
};
export const getIssuesFullSet = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  queryString: string | undefined,
  sortBy: string | undefined,
  order: "asc" | "desc",
  serviceConfig: ServiceConfig
): Promise<Issue[]> => {
  if (!sortBy) sortBy = "no";
  if (queryString) {
    queryString += `&sortBy=${sortBy}&order=${order}&full=true`;
  } else {
    queryString = `?sortBy=${sortBy}&order=${order}&full=true`;
  }

  return await apiRequest<Issue[]>(
    `/entities/${entityId}/location-groups/${locationGroupId}/Issues${
      queryString || ""
    }`,
    serviceConfig
  );
};

export const createIssue = async (
  entityId: string,
  Issue: Partial<IssueCreateForm> | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(`/entities/${entityId}/Issues`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(Issue),
  });
};
export const findOneIssue = async (
  entityId: string | undefined,
  IssueId: number | undefined,
  serviceConfig: ServiceConfig
): Promise<Issue> => {
  return await apiRequest<Issue>(
    `/entities/${entityId}/issues/${IssueId}`,
    serviceConfig
  );
};

export const deleteIssueAttachment = async (
  entityId: string | undefined,
  issueId: number | undefined,
  form: string | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest(
    `/entities/${entityId}/issues/${issueId}/attachments?fileId=${form}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
export const craetedIssueAttachment = async (
  entityId: string | undefined,
  issueId: number | undefined,
  attachments: number[],
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Issue>(
    `/entities/${entityId}/issues/${issueId}/attachments`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        files: attachments,
      }),
    }
  );
export const closeIssue = async (
  entityId: string | undefined,
  issueId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Issue>(
    `/entities/${entityId}/issues/${issueId}/close`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
export const craetedIssueNotification = async (
  entityId: string | undefined,
  IssueId: number | undefined,
  form: NotificationForm,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Issue>(
    `/entities/${entityId}/issues/${IssueId}/notifications`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
