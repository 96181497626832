import React, { ReactNode } from "react";

import { NxpAuthSpinner, NxpLayoutGlobal } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import { notify } from "@nexploretechnology/nxp-ui";
import AppLayout from "../AppLayout/AppLayout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedPageProps {
  requireActiveEntity?: boolean;
  component: ReactNode;
}

const AppProtectedPage: React.FC<AppProtectedPageProps> = ({
  requireActiveEntity,
  component,
}) => {
  const { keycloak } = useKeycloak();
  const appContext = useAppContext();
  useRouteAppContextUpdate();

  if (
    keycloak?.authenticated &&
    (!requireActiveEntity || appContext.activeEntity || appContext.activeUser)
  ) {
    return <AppLayout>{component}</AppLayout>;
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(
        "Cannot connect to authentication server. Please try refreshing your browser or contact support."
      );
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedPage;
