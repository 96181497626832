import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { createUseStyles } from "react-jss";

import { DeleteOutlined, ZoomInOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Avatar, Upload, UploadProps } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import _ from "lodash";

import { File } from "../../services/app";

interface Props {
  file: File;
  onDelete?: () => void;
  disableAction?: boolean;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    width: 150,

    "& .ant-upload-list-item-card-actions .anticon": {
      color: theme.palette.warning,
    },
  },
  content: {
    width: 150,
    height: 150,
    objectFit: "cover",
  },
  buttonBar: { display: "flex" },
  buttonBarSpacer: { display: "flex", flexGrow: 1 },
  button: {
    marginLeft: 0,
  },
}));

const AppFileView: React.FC<Props> = ({
  file,
  onDelete,
  disableAction = false,
}) => {
  const classes = useStyles();

  const [showLightbox, setShowLightbox] = useState<boolean>(false);

  if (!file) return null;

  const src = file.thumbnailUrl || file.url || file.originalUrl;
  const fileTypeIsImage = !_.includes(_.split(file?.type, "/"), "image");
  const props = {
    onChange(file: UploadChangeParam) {
      if (file?.file.status === "removed") onDelete && onDelete();
    },
  } as UploadProps;
  return (
    <div className={classes.root}>
      {!fileTypeIsImage ? (
        <a href={file.originalUrl} target="_blank" rel="noreferrer">
          <img className={classes.content} alt={file.name} src={src} />
        </a>
      ) : (
        <>
          <Avatar shape="square" size={150} />
          <Upload
            openFileDialogOnClick={true}
            {...props}
            disabled={disableAction}
            defaultFileList={[
              {
                uid: file?.id.toString(),
                name: file.name,
                status: "success",
                response: file.name, // custom error message to show
                url: file?.originalUrl,
              },
            ]}
          ></Upload>
        </>
      )}
      <div className={classes.buttonBar}>
        {fileTypeIsImage ? null : (
          <NxpButton
            className={classes.button}
            type="text"
            onClick={(e) =>
              window.open(
                file?.originalUrl,
                "_blank" // <- This is what makes it open in a new window.
              )
            }
          >
            <ZoomInOutlined />
          </NxpButton>
        )}
        <span className={classes.buttonBarSpacer} />
        {!fileTypeIsImage && onDelete && (
          <NxpButton
            className={classes.button}
            type="text"
            danger
            onClick={onDelete}
          >
            <DeleteOutlined />
          </NxpButton>
        )}
      </div>
      {showLightbox ? (
        <Lightbox
          mainSrc={file.url || file.originalUrl}
          onCloseRequest={() => setShowLightbox(false)}
        />
      ) : null}
    </div>
  );
};

export default AppFileView;
