import React from "react";
import { useTranslation } from "react-i18next";

import { CalendarFilled } from "@ant-design/icons";
import {
  LazyLoadStatus,
  NxpTableLazy,
  NxpTableProps,
} from "@nexploretechnology/nxp-ui";
import { Empty } from "antd";
import { ColumnsType } from "antd/lib/table";
import { get } from "lodash";
import moment from "moment";

import { Creator } from "../../services/app";
import { Issue } from "../../services/issue";

export interface AppIssueListItem extends Issue {
  creator: Creator;
}

interface LayoutProps {
  listItems: AppIssueListItem[] | null;
  lazyLoadStatus: LazyLoadStatus;
  onSortingChange: (field: any, order: any) => void;
  onLazyLoad: () => void;
  onRowClick: (defectId: number) => void;
  tableProps: NxpTableProps<any>;
}

const AppIssueListLayout: React.FC<LayoutProps> = (props) => {
  const { t: translation } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: translation("issue.number"),
      dataIndex: "code",
      width: 200,
      fixed: "left",
      sorter: true,
    },
    {
      title: translation("issue.type"),
      dataIndex: "issueType",
      width: 150,
      sorter: true,
      render: (value: unknown, _: AppIssueListItem) => get(value, "name"),
    },
    {
      title: translation("issue.category"),
      dataIndex: "issueCategory",
      width: 150,
      sorter: true,
      render: (value: unknown, _: AppIssueListItem) => get(value, "name"),
    },
    {
      title: translation("issue.subject"),
      dataIndex: "subject",
      width: 250,
      sorter: true,
    },
    {
      title: translation("issue.location"),
      dataIndex: "location",
      width: 300,
      sorter: true,
      render: (value: unknown, _: AppIssueListItem) => get(value, "fullPath"),
    },
    {
      title: translation("issue.createdOn"),
      dataIndex: "openDate",
      width: 150,
      sorter: true,
      render: (value: unknown) => (
        <>
          <CalendarFilled /> {moment(value as string).format("YYYY-MM-DD")}
        </>
      ),
    },
    {
      title: translation("issue.targetOn"),
      dataIndex: "targetDate",
      width: 150,
      sorter: true,
      render: (value: unknown) => (
        <>
          <CalendarFilled /> {moment(value as string).format("YYYY-MM-DD")}
        </>
      ),
    },
  ];

  return (
    <NxpTableLazy
      locale={{
        emptyText: (
          <>
            <Empty description={translation("app.common.noData")}></Empty>
          </>
        ),
      }}
      lazyLoadStatus={props.lazyLoadStatus}
      onLazyLoad={props.onLazyLoad}
      onChange={(pagination, filters, sorter: any) => {
        props.onSortingChange(sorter.field, sorter.order);
      }}
      columns={columns} //any for fixing antd type error
      dataSource={props.listItems || []}
      pagination={false}
      scroll={{ x: window.innerWidth - 130, y: window.innerHeight - 300 }}
      onRow={(record) => ({
        onClick: () => {
          if (props.onRowClick) {
            props.onRowClick(record.id);
          }
        },
      })}
      {...props.tableProps}
    />
  );
};

export default AppIssueListLayout;
