import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { File } from "../../../services/app";
import { Issue } from "../../../services/issue";
import AddAttachmentModal from "./";

interface Props {
  onAttachmentCreated: (form: File[]) => Promise<boolean>;
  status?: Issue["status"];
}

const AddIssueAttachhmentButton: React.FC<Props> = ({
  onAttachmentCreated,
  status = "open",
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={status === "closed"}
      >
        {translation("issue.relatedDocument.addDocument")}
      </NxpButton>
      <AddAttachmentModal
        showModal={modalVisible}
        handleDialogClose={() => setModalVisible(false)}
        onIssueDocumentCreated={onAttachmentCreated}
      />
    </>
  );
};

export default AddIssueAttachhmentButton;
