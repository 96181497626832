import { apiRequest, ServiceConfig } from "../utils/backend";
import { IssueDocuments } from "./issue";

export const createIssueDocuments = async (
  entityId: string | undefined,
  issueId: number | undefined,
  documentForm: Pick<IssueDocuments, "title" | "documentNumber"> | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issueId}/documents`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(documentForm),
    }
  );
};
export const updateIssueDocuments = async (
  entityId: string | undefined,
  issueId: number | undefined,
  documentId: number | undefined,
  documentForm: Pick<IssueDocuments, "title" | "documentNumber"> | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issueId}/documents/${documentId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        title: documentForm?.title,
        documentNumber: documentForm?.documentNumber,
      }),
    }
  );
};
export const deleteIssueDocument = async (
  entityId: string | undefined,
  issueId: number | undefined,
  documentId: number,
  serviceConfig: ServiceConfig
): Promise<Boolean> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issues/${issueId}/documents/${documentId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
