import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpModal } from "@nexploretechnology/nxp-ui";
import { Key } from "antd/lib/table/interface";
import { includes } from "lodash";

import AppFileList from "../../../components/AppFileList";
import AppUploader from "../../../components/AppUploader";
import { File } from "../../../services/app";

interface AddIssueModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onIssueDocumentCreated: (form: File[]) => Promise<boolean>;
}
const useStyles = createUseStyles((theme) => ({
  cell: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  deleteBtn: {
    float: "right",
    marginTop: theme.spacing(1),
  },
  fileContainer: {
    marginTop: theme.spacing(6),
  },
}));

const AddIssueContainer: React.FC<AddIssueModalProps> = ({
  showModal,
  handleDialogClose,
  onIssueDocumentCreated,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<Key[]>([]);
  const [issueAttachments, setIssueAttachments] = useState<File[]>([]);
  useEffect(() => {
    return () => {
      setIssueAttachments([]);
      setDeleteList([]);
    };
  }, [showModal]);
  const { t: translation } = useTranslation();
  const handleModalSave = () => {
    onIssueDocumentCreated(issueAttachments);
    handleDialogClose();
  };
  return (
    <NxpModal
      width="large"
      title={translation("issue.relatedDocument.addNewDocHeader")}
      visible={showModal}
      onCancel={handleDialogClose}
      footer={
        <NxpButton onClick={handleModalSave} disabled={loading}>
          {translation("app.common.save")}
        </NxpButton>
      }
    >
      <AppUploader
        onProgessing={setLoading}
        disabled={issueAttachments.length >= 5}
        onFileUploaded={(file) => {
          setIssueAttachments((preState) => [...preState, file]);
        }}
      />

      {deleteList.length > 0 && issueAttachments.length > 0 ? (
        <NxpButton
          danger
          className={classes.deleteBtn}
          onClick={() => {
            setIssueAttachments(
              issueAttachments.filter((file) => !includes(deleteList, file.id))
            );
            setDeleteList([] as Key[]);
          }}
        >
          {translation("app.common.delete")}
        </NxpButton>
      ) : (
        <></>
      )}
      <div className={classes.fileContainer}>
        <AppFileList fileList={issueAttachments} setIssueList={setDeleteList} />
      </div>
    </NxpModal>
  );
};

export default AddIssueContainer;
