import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Issue } from "../../../services/issue";
import { TaskCreateForm } from "../../../services/task";
import AddTaskContainer from "./";

interface Props {
  status?: Issue["status"];
  onTaskListCreated: (form: Partial<TaskCreateForm>) => Promise<boolean>;
}

const AddTaskButton: React.FC<Props> = ({
  status = "open",
  onTaskListCreated,
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={status === "closed"}
      >
        {translation("task.add.label")}
      </NxpButton>
      <AddTaskContainer
        showModal={modalVisible}
        handleDialogClose={() => setModalVisible(false)}
        onTaskListCreated={onTaskListCreated}
      />
    </>
  );
};

export default AddTaskButton;
