import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  LAZY_LOAD_PAGE_SIZE,
  LazyLoadStatus,
  NxpTableProps,
} from "@nexploretechnology/nxp-ui";
import _, { delay, get, isEmpty } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { IssuesCount } from "../../services/issue";
import { getTasks, Task } from "../../services/task";
import { notify } from "@nexploretechnology/nxp-ui";
import AppTaskListLayout from "./AppTaskListLayout";

interface Props {
  owner: boolean;
  queryString?: string;
  invalidateAt?: number;
  onIssueClick?: (defectId: number) => void;
  onSortingChange?: (sortBy: string, order: "asc" | "desc") => void;
  tableProps?: NxpTableProps<any>;
  status: keyof IssuesCount;
  keyword: string;
  refreshRow: Partial<{ taskId: number; status: string }>;
  setRefreshRow: (taskId?: number, status?: string) => void;
}

const AppTaskListContainer: React.FC<Props> = ({
  owner,
  queryString,
  invalidateAt = 0,
  onIssueClick = () => {},
  onSortingChange = () => {},
  tableProps = {},
  status = "all",
  keyword = " ",
  refreshRow = {},
  setRefreshRow = () => {},
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, activeUser } = appContext;
  const { entityId, issueId } = routeParams;

  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [listItems, setListItems] = useState<Task[]>([]);

  const [sortBy, setSortBy] = useState<undefined | string>("");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const queryStates = useRef<{
    queryString?: string;
    listItems: Task[];
    sortBy: undefined | string;
    order: "asc" | "desc";
    status: keyof IssuesCount;
    keyword: string;
  }>({
    queryString,
    listItems,
    sortBy,
    order,
    status,
    keyword,
  });

  queryStates.current = {
    queryString,
    listItems,
    sortBy,
    order,
    status,
    keyword,
  };

  const fetchData = useCallback(
    (limit: number, clear: boolean) => {
      setLazyLoadStatus(LazyLoadStatus.loading);
      getTasks(
        entityId,
        queryString,
        queryStates.current.status,
        queryStates.current.sortBy,
        queryStates.current.order,
        queryStates.current.keyword,
        issueId,
        owner ? undefined : activeUser?.id,
        clear
          ? 0
          : queryStates.current.listItems
          ? queryStates.current.listItems.length
          : 0,
        limit,
        serviceConfig
      )
        .then((tasks: Task[]) => {
          setLazyLoadStatus(LazyLoadStatus.ready);
          setListItems((listItems) => {
            return clear ? tasks : (listItems || []).concat(tasks);
          });
        })
        .catch((e) => notify.error(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, issueId, activeUser]
  );

  useEffect(() => {
    handleLazyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    order,
    invalidateAt,
    queryStates?.current?.keyword,
    issueId,
    status,
  ]);

  useEffect(() => {
    if (!isEmpty(refreshRow)) {
      if (
        refreshRow?.status !==
        get(
          listItems.find((task) => task?.id === refreshRow?.taskId),
          "status",
          ""
        )
      ) {
        handleLazyLoad(true);
      }
      delay(() => setRefreshRow({} as any), 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshRow]);

  const handleSortingChange = (field: any, order: any) => {
    setSortBy(field);
    setOrder(order === "descend" ? "desc" : "asc");
    onSortingChange(field, order === "descend" ? "desc" : "asc");
  };

  const handleLazyLoad = _.debounce((clear = false) => {
    if (lazyLoadStatus === LazyLoadStatus.ready) {
      fetchData(LAZY_LOAD_PAGE_SIZE, clear);
    }
  }, 500);
  return (
    <>
      <AppTaskListLayout
        listItems={listItems}
        lazyLoadStatus={lazyLoadStatus}
        onLazyLoad={handleLazyLoad}
        onSortingChange={handleSortingChange}
        onRowClick={onIssueClick}
        tableProps={tableProps}
      />
    </>
  );
};

export default AppTaskListContainer;
