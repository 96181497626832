import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpHeader } from "@nexploretechnology/nxp-ui";
import { Card, Col, Row, Spin, Tree } from "antd";
import { capitalize } from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { Creator } from "../../services/app";
import { Issue } from "../../services/issue";
import {
  NotificationsContext,
  SeleteObjPros,
} from "./NotificationSetupContainer";
import TabBar from "./TabBar/TabBar";

export interface AppIssueListItem extends Issue {
  creator: Creator;
}

interface LayoutProps {}
const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    // width: "calc(100% + 80px);",
  },
  header: {
    marginLeft: "25px",
    fontWeight: "bold",
  },
  tree: {
    background: theme.palette.backgroundLight,
    padding: "16px 40px",
    height: "calc(100vh - 125px)",
    "& .ant-tree-node-content-wrapper .ant-tree-iconEle": {
      width: "100%",
    },
  },
  notification: {
    color: theme.palette.text,
    "& .header .ant-checkbox-wrapper": {
      fontSize: theme.pxToRem(20),
      fontWeight: theme.fontWeight.bolder,
      margin: 0,
    },
    "& .frequency": {
      padding: theme.spacing(4, 0),
    },
  },
  content: {
    // height: "calc(100vh - 85px)",
    // overflowY: "scroll",
    padding: "16px 40px",
    flexGrow: 1,
  },
  logs: {},
}));

const NotificationSetupLayout: React.FC<LayoutProps> = () => {
  const { t: translation } = useTranslation();
  const inputEl = useRef(null);
  // const [selectedKey, setSelectedKey] = useState<React.Key[]>([1]);

  const classes = useStyles();
  const { issueType } = useAppContext();

  const {
    activeMediaTab,
    loading,
    setMediaTab,
    selectedTypeKey,
    setSelectedTypeKey,
    setSelectedNotification,
    selectedNotification,
  } = useContext(NotificationsContext);
  const handleLocationSelect = (
    selectedTypeKeys: React.Key[],
    info: SeleteObjPros
  ) => {
    if (selectedTypeKeys.length) {
      setSelectedTypeKey(selectedTypeKeys);
      setSelectedNotification(info);
    }
  };
  return (
    <>
      {issueType && selectedTypeKey && (
        <div className={classes.root}>
          <Row wrap>
            <Col span={4}>
              <div className={classes.tree}>
                <Card
                  title={capitalize(translation("issue.type"))}
                  bordered={false}
                >
                  {issueType && (
                    <Tree
                      ref={inputEl}
                      showIcon={false}
                      selectedKeys={selectedTypeKey}
                      defaultExpandParent={true}
                      defaultExpandAll={true}
                      onSelect={handleLocationSelect}
                      onLoad={() => console.log("tree load")}
                      treeData={issueType.map((type, index) => ({
                        ...type,
                        title: `${type.name}`,
                        key: index,
                      }))}
                    />
                  )}
                </Card>
              </div>
            </Col>
            <Col span={20}>
              <Spin spinning={loading && selectedTypeKey !== undefined}>
                <div className={classes.content}>
                  <NxpHeader
                    titleContent={`${translation("notification.title")} - ${
                      selectedNotification?.node?.title
                    }`}
                  />
                  <TabBar
                    activeKey={activeMediaTab}
                    onChange={(key: any) => setMediaTab(key)}
                  />
                </div>
              </Spin>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default NotificationSetupLayout;
