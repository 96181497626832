import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserRightList } from "../../../services/app";
import {
  getIssueCategories,
  getIssueTypes,
  IssueCategory,
} from "../../../services/issue";
import { ServiceConfig } from "../../../utils/backend";
import { notify } from "@nexploretechnology/nxp-ui";
import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const { t: translation } = useTranslation();
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      users: [],
      issueType: [],
      issueCategory: [],
      locationGroups: [],
      routeParams: {},
      locationFullPaths: [],
      media: ["email", "sms", "push"],
    }
  );
  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );
  const refetchIssueCategories = (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ): void => {
    if (!entityId) return;
    if (!serviceConfig) return;

    getIssueCategories(entityId, serviceConfig)
      .then((issueCategory: IssueCategory[]) => {
        handleAppContextCacheItemUpdate("issueCategory", issueCategory);
      })
      .catch((e: any) =>
        notify.error(
          `${translation("appRouter.getEnums.error")} ${entityId} : ${
            e.message
          }`
        )
      );
  };
  const refetchIssueTypes = async (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ) => {
    if (entityId && serviceConfig)
      try {
        const types = await getIssueTypes(entityId, serviceConfig);
        handleAppContextCacheItemUpdate("issueType", types);
        return types;
      } catch (error: any) {
        notify.error(
          `${translation("appRouter.issueType.error.error")} ${entityId} : ${
            error.message
          }`
        );
      }
  };

  const hasRight = (rightType: keyof UserRightList) => {
    if (!appContextCache.userRightList) return false;
    if (!appContextCache.userRightList[rightType]?.length) return false;
    const { userRightList } = appContextCache;
    for (const right of userRightList[rightType]) {
      if (right.source === "module") {
        return true;
      }
    }
    return false;
  };

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        refetchIssueCategories,
        refetchIssueTypes,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        hasRight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
