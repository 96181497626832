import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddDefectContainer from "./";

interface Props {
  onIssueCreated?: () => void;
}

const AddIssueButton: React.FC<Props> = ({ onIssueCreated }) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("issue.addIssue.label")}
      </NxpButton>
      <AddDefectContainer
        showModal={modalVisible}
        handleDialogClose={() => setModalVisible(false)}
        onIssueCreated={onIssueCreated}
      />
    </>
  );
};

export default AddIssueButton;
