import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NxpButton, NxpSelectUser } from "@nexploretechnology/nxp-ui";
import { Card, Col, Dropdown, Menu, Modal, Row } from "antd";

import useAppContext from "../../hooks/useAppContext";
import { CreatedTypeConfigForm, IssueType } from "../../services/typeConfig";
import EditTypeModal from "./EditTypeModal";

interface Props {
  type: IssueType;
  userIds: string[];
  originalUserIds: string[];
  onCoordinatorsChange: (typeId: number, userIds: string[]) => void;
  onEditRole: (typeId: number, form: CreatedTypeConfigForm) => Promise<boolean>;
  onDelete: (typeId: number) => Promise<boolean>;
}

const useStyles = createUseStyles((theme) => ({
  card: { marginBottom: 10 },
  selector: { width: "100%" },
}));

const TypeAssigneeList: React.FC<Props> = ({
  type,
  userIds = [],
  originalUserIds = [],
  onCoordinatorsChange,
  onEditRole,
  onDelete,
}) => {
  const classes = useStyles();
  const { users, hasRight } = useAppContext();

  const { t: translation } = useTranslation();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleValueChange = (value: string | string[]) => {
    if (typeof value == "string") {
      value = [value];
    }
    onCoordinatorsChange(type?.id, value);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `${translation("app.common.delete")} ${translation(
        "app.common.type"
      )}`,
      icon: <ExclamationCircleOutlined />,
      okText: translation("app.common.confirmLabel"),
      cancelText: translation("app.common.cancel"),
      content: `${translation("issue.detail.action")} ${translation(
        "app.common.confirm.action.warning"
      )}`,
      onOk: async () => {
        await onDelete(type?.id);
      },
      onCancel: () => {},
    });
  };

  return (
    <Card className={classes.card}>
      <Row>
        <Col span={5}>{type.name}</Col>
        <Col span={18}>
          <NxpSelectUser
            disabled={!hasRight("issue-type-assign")}
            className={classes.selector}
            mode="multiple"
            options={users.map((user) => ({
              name: user?.name,
              id: "" + user?.id,
            }))}
            filterOption={(input, option: any) => {
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            value={userIds.map((user) => "" + user)}
            onChange={handleValueChange}
          ></NxpSelectUser>
        </Col>
        <Col span={1}>
          {(hasRight("issue-type-update") || hasRight("issue-type-delete")) && (
            <Dropdown
              className="type-config-dropbox"
              overlay={
                <Menu
                  onClick={(menuInfo) => {
                    if (menuInfo.key === "edit") setShowEditModal(true);
                    if (menuInfo.key === "delete") handleDelete();
                  }}
                >
                  {hasRight("issue-type-update") && (
                    <Menu.Item aria-labelledby="edit" key="edit">
                      {translation("app.common.edit")}
                    </Menu.Item>
                  )}
                  {hasRight("issue-type-delete") && (
                    <Menu.Item aria-labelledby="delete" key="delete">
                      {translation("app.common.delete")}
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <NxpButton type="text" role="dropdrownBtn">
                <EllipsisOutlined />
              </NxpButton>
            </Dropdown>
          )}
        </Col>
      </Row>
      <EditTypeModal
        show={showEditModal}
        type={type}
        onEditType={onEditRole}
        onClose={() => setShowEditModal(false)}
      />
    </Card>
  );
};

export default TypeAssigneeList;
