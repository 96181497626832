import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpTableEditable, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";
import { head } from "lodash";

import useAppContext from "../../../hooks/useAppContext";
import { Media } from "../../../services/app";
import { NotificationsContext } from "../NotificationSetupContainer";

interface Props {
  activeKey: Media | string;
  onChange: (activeKey: string) => void;
}
const useStyles = createUseStyles((theme) => ({
  locationTabBar: {
    "& .ant-tabs-nav": {
      "& .ant-tabs-nav-wrap": {
        "& .ant-tabs-ink-bar-animated": {
          transition: "width 0.3s",
        },
      },
    },
  },
}));

const TabBar: React.FC<Props> = ({ activeKey, onChange }) => {
  // const { hasRight } = useAppContext();
  const classes = useStyles();

  const { t: translation } = useTranslation();
  const { media, hasRight } = useAppContext();
  const {
    editItem,
    dataSource,
    columns,
    onRowSave,
    onRowEdit,
    onRowCancel,
    selectedTypeKey,
    saveInProgress,
  } = useContext(NotificationsContext);
  return (
    <NxpTabs
      className={classes.locationTabBar}
      key="email-notification"
      activeKey={activeKey}
      defaultActiveKey={activeKey}
      onChange={onChange}
    >
      {media.map((category) => {
        return (
          <React.Fragment key={category}>
            <TabPane
              disabled={category === "email" ? false : true}
              tab={translation(`app.common.${category}`)}
              key={category.toString()}
            >
              <NxpTableEditable
                rowDisableEdit={() => !hasRight("notification-update")}
                hideDelete={true} // same as leaving this undefined
                scroll={{ y: window.innerHeight - 300 }}
                editItem={editItem}
                saveInProgress={saveInProgress}
                itemCompareKey="key"
                onRowEdit={onRowEdit}
                onRowSave={onRowSave}
                onRowCancel={onRowCancel}
                columns={columns}
                dataSource={dataSource[head(selectedTypeKey) as number]}
                pagination={false}
              />
            </TabPane>
          </React.Fragment>
        );
      })}
    </NxpTabs>
  );
};

export default TabBar;
