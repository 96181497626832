import { apiRequest, ServiceConfig } from "../utils/backend";
import { User } from "./app";

export interface IssueType {
  id: number;
  entityId: string;
  name: string;
  disabled: boolean;
  coordinators: User[];
}
export interface CreatedTypeConfigForm {
  name: string;
}
export const getIssueTypes = async (
  entityId: string | undefined,
  serviceConfig: ServiceConfig
): Promise<IssueType[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issue-types`,
    serviceConfig
  );
};
export const createIssueTypes = async (
  entityId: string | undefined,
  form: CreatedTypeConfigForm,
  serviceConfig: ServiceConfig
): Promise<IssueType[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issue-types`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
};
export const updateIssueTypes = async (
  entityId: string | undefined,
  issueTypeId: string | undefined,
  form: CreatedTypeConfigForm,
  serviceConfig: ServiceConfig
): Promise<IssueType[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issue-types/${issueTypeId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(form),
    }
  );
};
export const deleteIssueTypes = async (
  entityId: string | undefined,
  issueTypeId: string | undefined,
  serviceConfig: ServiceConfig
): Promise<IssueType[]> =>
  await apiRequest<any>(
    `/entities/${entityId}/issue-types/${issueTypeId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
export const updateIssueTypesCoordinators = async (
  entityId: string | undefined,
  issueTypeId: string | undefined,
  coordinators: string[],
  serviceConfig: ServiceConfig
): Promise<IssueType[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/issue-types/${issueTypeId}/coordinators`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify({
        coordinators,
      }),
    }
  );
};
