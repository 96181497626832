import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  DateFormatPattern,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Empty } from "antd";
import { ColumnProps } from "antd/lib/table";
import { get, isEmpty } from "lodash";
import moment from "moment";

import { IssueNotification } from "../../services/issue";

interface Props {
  issueNotifications: IssueNotification[];
}

const useStyles = createUseStyles((theme) => ({
  highlightedRow: {
    background: "rgba(2, 74, 148, 0.1)",
    "& td.ant-table-cell": {
      background: "rgba(2, 74, 148, 0.1)",
      overflow: "hidden",
    },
  },
  red: {
    color: theme.palette.warning,
  },
  green: {
    color: theme.palette.success,
  },
}));

const AppIssueNotification: React.FC<Props> = ({ issueNotifications }) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();

  const columns: ColumnProps<IssueNotification>[] = [
    {
      title: translation("notification.channel"),
      dataIndex: "media",
      width: 150,
      sorter: (a, b) => sorterForString(a.media, b.media),
    },
    {
      title: translation("notification.destination"),
      dataIndex: "recipient",
      width: 150,
      sorter: (a, b) =>
        sorterForString(get(a, "recipient.name"), get(b, "recipient.name")),
      render: (_, record) => get(record, "recipient.name"),
    },
    {
      title: translation("notification.status.label"),
      dataIndex: "status",
      width: 150,
      sorter: (a, b) => sorterForString(a.sendTime, b.sendTime),
      render: (_, record) => (
        <>
          <span>
            {
              <span
                className={
                  isEmpty(record?.sendTime) ? classes.red : classes.green
                }
              >
                {isEmpty(record?.sendTime)
                  ? translation("notification.status.pendding")
                  : translation("notification.status.sent")}
              </span>
            }
            {` at ${
              isEmpty(record?.sendTime)
                ? moment(Number(record?.createTime)).format(
                    DateFormatPattern.dateWithTime
                  )
                : moment(Number(record?.sendTime)).format(
                    DateFormatPattern.dateWithTime
                  )
            }`}
          </span>
        </>
      ),
    },
  ];

  return (
    <NxpTable
      locale={{
        emptyText: (
          <>
            <Empty description={translation("app.common.noData")}></Empty>
          </>
        ),
      }}
      useDefaultHeight
      pagination={false}
      scroll={{ y: window?.innerHeight - 600 }}
      columns={columns} //any for fixing antd type error
      dataSource={issueNotifications}
    />
  );
};

export default AppIssueNotification;
