import React from "react";
import { useTranslation } from "react-i18next";

import {
  DateFormatPattern,
  LazyLoadStatus,
  NxpDateDisplay,
  NxpTableLazy,
  NxpTableProps,
} from "@nexploretechnology/nxp-ui";
import { Empty } from "antd";
import { ColumnsType } from "antd/lib/table";
import { get } from "lodash";
import moment from "moment";

import { Creator } from "../../services/app";
import { Issue } from "../../services/issue";
import { Task } from "../../services/task";

export interface AppIssueListItem extends Issue {
  creator: Creator;
}

interface LayoutProps {
  listItems: Task[];
  lazyLoadStatus: LazyLoadStatus;
  onSortingChange: (field: any, order: any) => void;
  onLazyLoad: () => void;
  onRowClick: (defectId: number) => void;
  tableProps: NxpTableProps<any>;
}

const AppTaskListLayout: React.FC<LayoutProps> = (props) => {
  const { t: translation } = useTranslation();
  const columns: ColumnsType<Task> = [
    {
      title: translation("task.assignBy"),
      dataIndex: "creator",
      width: 150,
      fixed: "left",
      render: (_, record) => get(record, "creator.name"),
    },
    {
      title: translation("task.subject"),
      dataIndex: "subject",
      width: 150,
      sorter: true,
    },
    {
      title: translation("task.description"),
      dataIndex: "description",
      width: 300,
    },
    {
      title: translation("task.endDate"),
      dataIndex: "targetDate",
      width: 150,
      sorter: true,
      render: (_, record) => {
        return (
          <NxpDateDisplay
            date={moment(record?.targetDate).toDate()}
            format={DateFormatPattern.date}
          />
        );
      },
    },
    {
      title: translation("task.closeOutDoc"),
      dataIndex: "needDocument",
      width: 150,
      sorter: true,
      render: (_, record) =>
        record?.needDocument
          ? translation("app.common.yes")
          : translation("app.common.no"),
    },
    {
      title: translation("task.status"),
      dataIndex: "status",
      width: 150,
      sorter: true,
    },
  ];

  return (
    <NxpTableLazy
      locale={{
        emptyText: (
          <>
            <Empty description={translation("app.common.noData")}></Empty>
          </>
        ),
      }}
      lazyLoadStatus={props.lazyLoadStatus}
      onLazyLoad={props.onLazyLoad}
      onChange={(pagination, filters, sorter: any) => {
        props.onSortingChange(sorter.field, sorter.order);
      }}
      columns={columns} //any for fixing antd type error
      dataSource={props.listItems || []}
      pagination={false}
      scroll={{ x: window.innerWidth - 130, y: window.innerHeight - 300 }}
      onRow={(record) => ({
        onClick: () => {
          if (props.onRowClick) {
            props.onRowClick(record.id);
          }
        },
      })}
      {...props.tableProps}
    />
  );
};

export default AppTaskListLayout;
