import { apiRequest, ServiceConfig } from "../utils/backend";
import { Media, User } from "./app";

export interface Notification {
  id: number;
  entityId: string;
  issueCategory: {
    id: number;
    entityId: string;
    name: string;
    disabled: boolean;
  };
  issueType: {
    id: number;
    entityId: string;
    name: string;
    disabled: boolean;
  };
  media: string;
  recipients: User[];
  sendTime?: string;
  template?: string;
  createTime?: string;
  context?: {
    message: string;
    issueUrl: string;
    recipient: string;
    issueSubject: string;
  };
}
export interface NotificationForm {
  message: number;
  media: string;
  recipients: string[];
}
export interface UpdateNotificationForm {
  issueType: number;
  issueCategory: number;
  media: Media | string;
  recipients: string[];
}
export const getNotifications = async (
  entityId: string | undefined,
  serviceConfig: ServiceConfig
): Promise<Notification[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/notification-configs`,
    serviceConfig
  );
};
export const addNotifications = async (
  entityId: string | undefined,
  form: NotificationForm,
  serviceConfig: ServiceConfig
): Promise<Notification[]> => {
  return await apiRequest<any>(
    `/entities/${entityId}/notification-configs`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
};
export const updateNotification = async (
  entityId: string | undefined,
  notificationId: number,
  form: UpdateNotificationForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/notification-configs/${notificationId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(form),
    }
  );
};
export const deleteNotification = async (
  entityId: string | undefined,
  notificationId: number | undefined,
  serviceConfig: ServiceConfig
): Promise<Boolean> => {
  return await apiRequest<any>(
    `/entities/${entityId}/notification-configs/${notificationId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
export const createNotification = async (
  entityId: string | undefined,
  form: UpdateNotificationForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/notification-configs`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
};
