import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";

interface Props {
  show: boolean;
  onClose: () => void;
  onAddRole: (form: AddRoleForm) => Promise<boolean>;
}

export interface AddRoleForm {
  name: string;
  code: string;
  description: string;
  userIds: number[];
}

const emptyForm = {
  name: "",
  code: "",
  description: "",
  userIds: [],
};

const AddRoleModal: React.FC<Props> = ({ show, onAddRole, onClose }) => {
  const { users } = useAppContext();

  const { t: translation } = useTranslation();

  const [form, setForm] = useState<AddRoleForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("roleSetting.roleNameIsRequired")),
    code: yup
      .string()
      .nullable()
      .required(translation("roleSetting.roleCodeIsRequired")),
    description: yup.string().nullable(),
    userIds: yup.array().nullable(),
  });

  useEffect(() => {
    return () => {
      setForm(emptyForm);
      setSaving(false);
    };
  }, [show]);

  const formItems: NxpFormGridItemProps<AddRoleForm>[] = [
    {
      controlType: "input",
      label: translation("roleSetting.roleName"),
      itemFieldName: "name",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: translation("roleSetting.roleCode"),
      itemFieldName: "code",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.description"),
      itemFieldName: "description",
      required: false,
      span: 24,
    },
    {
      controlType: "selectUser",
      label: translation("app.common.users"),
      itemFieldName: "userIds",
      span: 24,
      controlProps: {
        allowClear: true,
        mode: "multiple",
        options: users.map((user) => ({ id: "" + user.id, name: user.name })),
        filterOption: (input, option: any) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
      },
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onAddRole(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , , saveWithValidate] = useYupValidate<AddRoleForm>(
    form,
    formSchema,
    handleSaveValidated
  );

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpModal
      width="large"
      title={translation("roleSetting.addRole")}
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {translation("roleSetting.cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleModal;
