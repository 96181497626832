import React, { useCallback, useEffect, useState } from "react";
import {
  CreatedTypeConfigForm,
  createIssueTypes,
  deleteIssueTypes,
  IssueType,
  updateIssueTypes,
  updateIssueTypesCoordinators,
} from "../../services/typeConfig";
import useAppContext from "../../hooks/useAppContext";
import { notify } from "@nexploretechnology/nxp-ui";
import TypeConfigLayout from "./TypeConfigLayout";
import { useTranslation } from "react-i18next";
interface Props {}

const TypeConfigContainer: React.FC<Props> = () => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, issueType, refetchIssueTypes, users } =
    appContext;
  const { entityId } = routeParams;
  const [currentTyps, setCurrentTyps] = useState<IssueType[]>(issueType);
  const [userIds, setUserIds] = useState<any[][]>([] as any[][]);
  const [originalUserIds] = useState<string[][]>([] as string[][]);
  const { t: translation } = useTranslation();

  const refreshType = useCallback(() => {
    const fretching = async () => {
      const refreshTypePromise = await Promise.resolve(
        refetchIssueTypes(entityId, serviceConfig)
      );
      setCurrentTyps(refreshTypePromise as IssueType[]);
    };
    fretching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let initUserList = [];

    for (let index = 0; index < issueType.length; index++) {
      initUserList[index] = issueType[index].coordinators.map(
        (user) => user.id
      );
    }
    setUserIds(initUserList);
  }, [issueType, users]);
  const handleChange = async (type: number, coordinators: string[]) => {
    if (!type) return;
    const index = issueType.findIndex(
      (existingRole) => existingRole.id === type
    );
    const newUserIds = [...userIds];
    newUserIds[index] = coordinators;
    try {
      await updateIssueTypesCoordinators(
        entityId,
        type.toString(),
        coordinators,
        serviceConfig
      );
      refreshType();
      setUserIds(newUserIds);
      notify.success(` Coordination update sucessfully`);
      return true;
    } catch (e) {
      notify.error(
        `Failed config coordinators : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  const handleAdd = async (form: CreatedTypeConfigForm): Promise<boolean> => {
    if (!entityId) return false;
    if (!currentTyps) return false;
    try {
      await createIssueTypes(entityId, form, serviceConfig);
      refreshType();
      notify.success(`${form.name} created sucessfully`);
      return true;
    } catch (e) {
      notify.error(
        `Failed adding new Type : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };
  const handleEdit = async (
    typeId: number,
    form: CreatedTypeConfigForm
  ): Promise<boolean> => {
    if (!entityId) return false;
    if (!issueType) return false;
    try {
      await updateIssueTypes(entityId, typeId.toString(), form, serviceConfig);
      refreshType();
      notify.success(`${translation("app.common.type")} ${form.name} Updated`);
      return true;
    } catch (e) {
      notify.error(
        `Failed editing Role : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };
  const handleDelete = async (typeId: number): Promise<boolean> => {
    if (!entityId) return false;
    if (!issueType) return false;
    try {
      await deleteIssueTypes(entityId, typeId.toString(), serviceConfig);
      refreshType();
      notify.success(
        `${translation("app.common.type")} ${
          issueType.find((type) => type?.id === typeId)?.name
        } deleted`
      );
      return true;
    } catch (e) {
      notify.error(
        `${translation("app.common.delete")} ${translation(
          "app.common.type"
        )} failed : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };
  return (
    <TypeConfigLayout
      currenType={currentTyps}
      onCoordinatorChange={handleChange}
      onDelete={handleDelete}
      onAdd={handleAdd}
      onEdit={handleEdit}
      userIds={userIds}
      originalUserIds={originalUserIds}
    />
  );
};

export default TypeConfigContainer;
