import { apiRequest, ServiceConfig } from "../utils/backend";
import { Role } from "./role";

export type Media = "email" | "sms" | "push";
export interface Entity {
  id: string;
  name: string;
  code: string; // added for CPCS-976
}

export interface RegisterType {
  value: string;
  label: string;
}

export interface User {
  entityId: string;
  id: string;
  name: string;
  email: string;
}

export interface EntityParameter {
  entityId: string;
  dueDate: number;
  projectEntity: string;
  nextInspectionDate: number;
  reinspectionDate: number;
  dueDateReminderForSubcontractor: number;
  dueDateReminderForInspector: number;
  dueDatePriority: number;
  nextInspectionDatePriority: number;
  reinspectionDatePriority: number;
}

export interface Creator {
  id: number;
  entityId: string;
  cpcsId: string;
  name: string;
  disabled?: boolean;
  keycloakId: string;
  email: string;
  roles: Role[];
}

export interface Team {
  id: number;
  name: string;
  description: string;
  members: User[];
}
export interface Location {
  id: number;
  entityId: string;
  code: string;
  name: string;
  fullPath: string;
  isLocationGroup: boolean;
  parent: Location;
  children: Location[];
}

export interface NxpEnum {
  id: number;
  type: string;
  name: string;
}

export interface File {
  id: number;
  name: string;
  url: string;
  thumbnailUrl: string;
  originalUrl: string;
  uploadedBy: Creator;
  createdAt: string;
  type?: string;
  updatedOn?: Date;
}

export interface UserRightListItem {
  source: "module";
  entity?: string;
}
export interface UserRightList {
  "issue-read": UserRightListItem[];
  "issue-manage": UserRightListItem[];

  "issue-type-assign": UserRightListItem[];
  "issue-type-create": UserRightListItem[];
  "issue-type-delete": UserRightListItem[];
  "issue-type-read": UserRightListItem[];
  "issue-type-update": UserRightListItem[];

  "task-read": UserRightListItem[];

  "notification-create": UserRightListItem[];
  "notification-delete": UserRightListItem[];
  "notification-read": UserRightListItem[];
  "notification-update": UserRightListItem[];

  "role-assign": UserRightListItem[];
  "role-create": UserRightListItem[];
  "role-delete": UserRightListItem[];
  "role-read": UserRightListItem[];
  "role-update": UserRightListItem[];

  "right-read": UserRightListItem[];
  "right-update": UserRightListItem[];
}
export const getUserRightList = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<UserRightList>(
    `/entities/${entityId}/user-right-list`,
    serviceConfig
  );
};

export const getEntityInfo = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<Entity> => {
  return await apiRequest<Entity>(`/entities/${entityId}`, serviceConfig).then(
    (entity) => {
      return {
        ...entity,
        id: entity.id.toString(), //handle case when entityId is sent as integer from backend
      };
    }
  );
};

export const getUsers = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(
    `/entities/${entityId}/users?refetchFromCpcs=true`,
    serviceConfig
  ).then((res) => res);
};

export const getMe = async (entityId: string, serviceConfig: ServiceConfig) => {
  return await apiRequest<User>(
    `/entities/${entityId}/users/me`,
    serviceConfig
  );
};
export interface CPCSDictionary {
  id: number;
  entity_id: number;
  is_deleted: boolean;
  name: string;
  code: string;
  created_at: Date;
  updated_at: Date;
  description: string;
  type: string;
  dictionaries: CPCSDictionaryItem[];
}

export interface CPCSDictionaryItem {
  name: string;
  description: string;
  code: string;
  children: CPCSDictionaryItem[];
}

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}

export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getCPCSDictionaryList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<CPCSDictionary[]>(
    `/entity/entities/${serviceConfig.entityId}/categories/own`,
    serviceConfig
  );
};

export const getCPCSDictionary = async (
  dictionaryKey: string,
  serviceConfig: ServiceConfig
) => {
  try {
    return await apiRequest<CPCSDictionary>(
      `/entity/entities/${serviceConfig.entityId}/categories/${dictionaryKey}`,
      serviceConfig
    );
  } catch (ex) {
    console.error(ex);
  }
};
export const getLocationGroups = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(`/entities/${entityId}/locations`, serviceConfig);
};

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};

export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};

export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};
