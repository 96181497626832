import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Nxp404 } from "@nexploretechnology/nxp-ui";

import IssueDetailsContainer from "../../../pages/IssueDetails";
import IssueListing from "../../../pages/IssuesListing";
import Notification from "../../../pages/Notification";
import RightSetting from "../../../pages/RightSetting";
import RoleSetting from "../../../pages/RoleSetting";
import TaskListing from "../../../pages/TasksListing";
import TypeConfig from "../../../pages/TypeConfig";
import AppProtectedPage from "./AppProtectedPage";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/entities" />} />
        <Route
          path="/entities"
          element={
            <Navigate to="/entities/50bff10e-cfbe-4b24-91fb-32cea6fd1c2b/issues" />
          }
        />
        <Route
          path="/entities/:entityId/issues"
          element={<AppProtectedPage component={<IssueListing />} />}
        />
        <Route
          path="/entities/:entityId/issues/:issueId"
          element={<AppProtectedPage component={<IssueDetailsContainer />} />}
        />
        <Route
          path="/entities/:entityId/tasks"
          element={
            <AppProtectedPage requireActiveEntity component={<TaskListing />} />
          }
        />
        <Route
          path="/entities/:entityId/roles"
          element={<AppProtectedPage component={<RoleSetting />} />}
        />
        <Route
          path="/entities/:entityId/rights"
          element={<AppProtectedPage component={<RightSetting />} />}
        />
        <Route
          path="/entities/:entityId/notification"
          element={<AppProtectedPage component={<Notification />} />}
        />
        <Route
          path="/entities/:entityId/type-config"
          element={<AppProtectedPage component={<TypeConfig />} />}
        />
        <Route
          path="/*"
          element={<AppProtectedPage component={<Nxp404 />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
