import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  DateFormatPattern,
  NxpDateDisplay,
  NxpTable,
} from "@nexploretechnology/nxp-ui";
import { Avatar, Empty } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Key, TableRowSelection } from "antd/lib/table/interface";
import { get } from "lodash";
import moment from "moment";

import { File } from "../../services/app";
import { Issue } from "../../services/issue";

interface Props {
  fileList: File[];
  setIssueList: (form: Key[]) => void;
  status?: Issue["status"];
}
const useStyles = createUseStyles((theme) => ({
  root: {},
}));
const AppFileList: React.FC<Props> = ({
  fileList,
  setIssueList,
  status = "open",
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
  const classes = useStyles();
  const { t: translation } = useTranslation();
  useEffect(() => {
    if (selectedRowKeys) setIssueList(selectedRowKeys);
    return () => {
      setSelectedRowKeys([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns: ColumnProps<File>[] = [
    {
      title: translation("issue.attachment.thumbnail"),
      dataIndex: "thumbnailUrl",
      width: 180,
      fixed: "left",
      render: (_, record) => {
        return <Avatar shape="square" size={50} src={record?.thumbnailUrl} />;
      },
    },
    {
      title: translation("issue.attachment.type"),
      dataIndex: "type",
      width: 150,
    },
    {
      title: translation("issue.attachment.fileName"),
      dataIndex: "name",
      width: 150,
    },
    {
      title: translation("issue.attachment.by"),
      dataIndex: "uploadedBy",
      width: 100,
      render: (val) => get(val, "name"),
    },
    {
      title: translation("issue.attachment.date"),
      dataIndex: "updatedOn",
      width: 150,
      render: (_, record) => {
        return (
          <NxpDateDisplay
            date={moment(Number(record?.createdAt)).toDate()}
            format={DateFormatPattern.date}
          />
        );
      },
    },
  ];
  const onSelectChange = (selectedRowKeys: Key[]) => {
    setIssueList(selectedRowKeys);
  };
  const rowSelection: TableRowSelection<File> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: status === "closed",
    }),
  };
  return (
    <NxpTable
      locale={{
        emptyText: (
          <>
            <Empty description={translation("app.common.noData")}></Empty>
          </>
        ),
      }}
      className={classes.root}
      useDefaultHeight
      pagination={false}
      columns={columns} //any for fixing antd type error
      rowKey={"id"}
      rowClassName={"issue_file"}
      dataSource={fileList}
      scroll={{ y: window.innerHeight - 600 }}
      rowSelection={rowSelection}
      onRow={(record: any) => ({
        onClick: () => {
          window.open(
            record?.originalUrl,
            "_blank" // <- This is what makes it open in a new window.
          );
        },
      })}
    />
  );
};

export default AppFileList;
