import { Col, Row, Spin } from "antd";
import clsx from "clsx";
import { delay } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";
import {
  notify,
  NxpFormItem,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";

import AppTasksList from "../../components/AppTasksList";
import useAppContext from "../../hooks/useAppContext";
import { Issue, IssuesCount } from "../../services/issue";
import {
  closeTask,
  createTask,
  createTaskResponse,
  getOneTask,
  getTasksCount,
  markCloseTask,
  rejectTask,
  Task,
  TaskCreateForm,
  TaskResponse,
  TaskResponseFrom,
} from "../../services/task";
import { IssueDetailsContext } from "../IssueDetails/IssuesDetailsContainer";
import AddTaskButton from "./AddTask/AddTaskButton";
import TaskDetailModal from "./TaskReview";

interface Props {
  status?: Issue["status"];
  owner?: boolean;
}
const useStyles = createUseStyles((theme) => ({
  root: {
    "& .npx-tab-container": {
      marginTop: theme.pxToRem(-20),
    },
    "& .nxp-header-btn": {
      zIndex: 1000,
      position: "absolute",
      right: 0,
    },
  },
}));
interface TaskDetailsContextType {
  onFetchOneTask: (taskId: number) => Promise<Task | boolean>;
  onTaskResponseCreated: (
    taskId: number,
    form: Partial<TaskResponseFrom>
  ) => Promise<boolean>;
  onTaskClosed: (taskId: number) => Promise<boolean>;
  onTaskMarkClosed: (taskId: number) => Promise<boolean>;
  onTaskRejected: (taskId: number) => Promise<boolean>;
  onTaskListCreated: (form: Partial<TaskCreateForm>) => Promise<boolean>;
  taskId: number;
  taskIssueId: number;
  setTaskIssueId: (id: number) => void;
  taskResponse: TaskResponse[];
  setTaskResponses: (list: TaskResponse[]) => void;
}
export const TaskDetailsContext = React.createContext<TaskDetailsContextType>(
  {} as TaskDetailsContextType
);
const TaskListing: React.FC<Props> = ({ status = "open", owner = false }) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const { serviceConfig, routeParams, hasRight, activeUser } = appContext;
  const { entityId, issueId } = routeParams;
  const { t: translation } = useTranslation();
  const searchPhase = useLocation()?.search;
  const [taskDetailId, setTaskDetailId] = useState<number>();
  const [invalidateAt, setInvalidateAt] = useState<number>(0);
  const [extraQueries] = useState<string[]>([]);
  const [, setSortBy] = useState<undefined | string>("no"); // for reference only, AppDefectList handle sorting on it's own
  const [, setOrder] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
  const [activeTab, setActiveTab] = useState<string>("all");
  const [issuesCount, setIssuesCount] = useState<IssuesCount>();
  const [keyword, setKeyword] = useState<string>("");
  const [refreshRow, setRefreshRow] = useState<{
    taskId: number;
    status: string;
  }>();
  const [showTaskDetailModal, setShowTaskDetailModal] =
    useState<boolean>(false);

  const { onFetchingIssue } = useContext(IssueDetailsContext);
  const [taskResponses, setTaskResponses] = useState<TaskResponse[]>([]);
  const [taskIssueId, setTaskIssueId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  let queryString = [searchPhase, ...extraQueries].join("&");

  if (!queryString.startsWith("?")) queryString = `?${queryString}`;

  const handleSortingChange = (sortBy: string, order: "asc" | "desc") => {
    setSortBy(sortBy);
    setOrder(order);
  };
  async function getCount() {
    if (entityId) {
      const count = await getTasksCount(
        entityId,
        issueId,
        issueId && owner ? undefined : activeUser?.id,
        serviceConfig
      );
      if (!count) {
        return;
      }
      setIssuesCount(count);
    }
  }
  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, issueId !== undefined, activeUser]);

  let title = translation("task.subject");

  const handleDefectCreated = () => {
    setInvalidateAt(new Date().getTime());
    getCount();
    if (typeof onFetchingIssue === "function") onFetchingIssue();
  };

  const handleTaskClosed = async (taskId: number): Promise<boolean> => {
    if (!entityId) return false;
    try {
      await closeTask(entityId, taskId, serviceConfig);
      getCount();
      if (typeof onFetchingIssue === "function") onFetchingIssue();
      notify.success(`Task closed sucessfully`);
      return true;
    } catch (e) {
      notify.error(
        `Failed adding task response : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };
  const handleTaskCreated = async (
    form: Partial<TaskCreateForm>
  ): Promise<boolean> => {
    if (!entityId) return false;
    try {
      const task = await createTask(entityId, issueId, form, serviceConfig);
      if (task) {
        handleDefectCreated();
        notify.success(`Task Response created sucessfully`);
      }
      return true;
    } catch (e) {
      notify.error(
        `Failed adding task created : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };
  const handleTaskMarkClosed = async (taskId: number): Promise<boolean> => {
    if (!entityId) return false;
    try {
      await markCloseTask(entityId, taskId, serviceConfig);
      getCount();
      notify.success(`Task mark-closed sucessfully`);
      return true;
    } catch (e) {
      notify.error(
        `Failed adding task response : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };
  const handleTaskRejected = async (taskId: number): Promise<boolean> => {
    if (!entityId) return false;
    try {
      await rejectTask(entityId, taskId, serviceConfig);
      getCount();
      notify.success(`Task rejected sucessfully`);
      return true;
    } catch (e) {
      notify.error(
        `Failed adding task response : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };
  const handleGetOneTask = async (taskId: number): Promise<Task | boolean> => {
    if (!entityId) return false;
    try {
      const task = await getOneTask(entityId, taskId, serviceConfig);
      setTaskIssueId(task?.issue?.id);
      setTaskResponses(task?.responses);
      return task;
    } catch (e) {
      notify.error(
        `Failed adding task response : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };
  const handleAddTaskRespnose = async (
    taskId: number,
    form: Partial<TaskResponseFrom>
  ): Promise<boolean> => {
    if (!entityId && !issueId) return false;
    try {
      await createTaskResponse(
        entityId,
        taskIssueId,
        taskId,
        form,
        serviceConfig
      );
      notify.success(`Task Response created sucessfully`);

      return true;
    } catch (e) {
      notify.error(
        `Failed adding task response : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <Spin spinning={loading} delay={500}>
        <NxpHeader
          titleContent={
            <>
              <span title="tasks">{issueId ? "" : title}</span>
            </>
          }
          actionContent={
            <Row gutter={10} className="nxp-header-btn">
              <Col>
                <NxpFormItem
                  controlType={"input"}
                  controlProps={{
                    value: keyword,
                    onChange: (e) => setKeyword(e.target.value),
                    prefix: <SearchOutlined />,
                    placeholder: translation("app.common.search"),
                  }}
                />
              </Col>
              {issueId && hasRight("issue-manage") && (
                <Col>
                  <AddTaskButton
                    status={status}
                    onTaskListCreated={handleTaskCreated}
                  />
                </Col>
              )}
            </Row>
          }
        />
        {hasRight("task-read") && (
          <>
            <NxpTabs
              defaultActiveKey="all"
              onChange={(activeKey) => setActiveTab(activeKey)}
              className={issueId ? "npx-tab-container" : ""}
            >
              <TabPane
                tab={translation("issue.tab.all", { count: issuesCount?.all })}
                key={"all"}
                className={clsx("all")}
              >
                {entityId && (
                  <AppTasksList
                    owner={owner}
                    keyword={keyword}
                    status={activeTab as keyof IssuesCount}
                    queryString={queryString}
                    invalidateAt={invalidateAt}
                    onIssueClick={(taskId: number) => {
                      setLoading(true);
                      setTaskDetailId(taskId);
                      delay(() => {
                        setShowTaskDetailModal(true);
                        setLoading(false);
                      }, 1000);
                    }}
                    onSortingChange={handleSortingChange}
                    tableProps={{ useDefaultHeight: true }}
                    refreshRow={
                      refreshRow as { taskId: number; status: string }
                    }
                    setRefreshRow={setRefreshRow as any}
                  />
                )}
              </TabPane>
              <TabPane
                tab={translation("issue.tab.open", {
                  count: issuesCount?.open,
                })}
                key={"open"}
                className="open"
              >
                {entityId && (
                  <AppTasksList
                    owner={owner}
                    keyword={keyword}
                    status={activeTab as keyof IssuesCount}
                    queryString={queryString}
                    invalidateAt={invalidateAt}
                    onIssueClick={(taskId: number) => {
                      setLoading(true);
                      setTaskDetailId(taskId);
                      delay(() => {
                        setShowTaskDetailModal(true);
                        setLoading(false);
                      }, 1000);
                    }}
                    onSortingChange={handleSortingChange}
                    tableProps={{ useDefaultHeight: true }}
                    refreshRow={
                      refreshRow as { taskId: number; status: string }
                    }
                    setRefreshRow={setRefreshRow as any}
                  />
                )}
              </TabPane>
              <TabPane
                tab={translation("issue.tab.mardkClosed", {
                  count: issuesCount?.["marked-closed"],
                })}
                key={"marked-closed"}
                className="marked-closed"
              >
                {entityId && (
                  <AppTasksList
                    owner={owner}
                    keyword={keyword}
                    status={activeTab as keyof IssuesCount}
                    queryString={queryString}
                    invalidateAt={invalidateAt}
                    onIssueClick={(taskId: number) => {
                      setLoading(true);
                      setTaskDetailId(taskId);
                      delay(() => {
                        setShowTaskDetailModal(true);
                        setLoading(false);
                      }, 1000);
                    }}
                    onSortingChange={handleSortingChange}
                    tableProps={{ useDefaultHeight: true }}
                    refreshRow={
                      refreshRow as { taskId: number; status: string }
                    }
                    setRefreshRow={setRefreshRow as any}
                  />
                )}
              </TabPane>
              <TabPane
                tab={translation("issue.tab.overdue", {
                  count: issuesCount?.overdue,
                })}
                key="overdue"
                className="open"
              >
                {entityId && (
                  <AppTasksList
                    owner={owner}
                    keyword={keyword}
                    status={activeTab as keyof IssuesCount}
                    queryString={queryString}
                    invalidateAt={invalidateAt}
                    onIssueClick={(taskId: number) => {
                      setLoading(true);
                      setTaskDetailId(taskId);
                      delay(() => {
                        setShowTaskDetailModal(true);
                        setLoading(false);
                      }, 1000);
                    }}
                    onSortingChange={handleSortingChange}
                    tableProps={{ useDefaultHeight: true }}
                    refreshRow={
                      refreshRow as { taskId: number; status: string }
                    }
                    setRefreshRow={setRefreshRow as any}
                  />
                )}
              </TabPane>
              <TabPane
                tab={translation("issue.tab.closed", {
                  count: issuesCount?.closed,
                })}
                key="closed"
                className="closed"
              >
                {entityId && (
                  <AppTasksList
                    owner={owner}
                    keyword={keyword}
                    status={activeTab as keyof IssuesCount}
                    queryString={queryString}
                    invalidateAt={invalidateAt}
                    onIssueClick={(taskId: number) => {
                      setLoading(true);
                      setTaskDetailId(taskId);
                      delay(() => {
                        setShowTaskDetailModal(true);
                        setLoading(false);
                      }, 1000);
                    }}
                    onSortingChange={handleSortingChange}
                    tableProps={{ useDefaultHeight: true }}
                    refreshRow={
                      refreshRow as { taskId: number; status: string }
                    }
                    setRefreshRow={setRefreshRow as any}
                  />
                )}
              </TabPane>
            </NxpTabs>
            {taskDetailId && (
              <TaskDetailsContext.Provider
                value={{
                  onFetchOneTask: handleGetOneTask,
                  onTaskResponseCreated: handleAddTaskRespnose,
                  onTaskClosed: handleTaskClosed,
                  onTaskMarkClosed: handleTaskMarkClosed,
                  onTaskRejected: handleTaskRejected,
                  onTaskListCreated: handleTaskCreated,
                  taskId: taskDetailId,
                  taskResponse: taskResponses,
                  setTaskResponses,
                  taskIssueId,
                  setTaskIssueId,
                }}
              >
                <TaskDetailModal
                  taskId={taskDetailId}
                  show={showTaskDetailModal}
                  onClose={(taskId, status) => {
                    setRefreshRow({ taskId, status });
                    setShowTaskDetailModal(false);
                  }}
                />
              </TaskDetailsContext.Provider>
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default TaskListing;
