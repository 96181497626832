import React, { useCallback, useEffect, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import { notify } from "@nexploretechnology/nxp-ui";
import {
  batchAddRoleAssignees,
  createRole,
  deleteRole,
  editRole,
  getRoles,
  Role,
  updateRoleAssignees,
} from "../../services/role";
import RoleSettingLayout from "./RoleSettingLayout";
import { AddRoleForm } from "./AddRoleModal";
import { User } from "../../services/app";
import { BatchAssignForm } from "./BatchAssignModal";
import { EditRoleForm } from "./EditRoleModal";

import { useTranslation } from "react-i18next";

interface Props {}

const RoleSettingContainer: React.FC<Props> = () => {
  const { routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;
  const { t: translation } = useTranslation();

  const [roles, setRoles] = useState<Role[] | undefined>(undefined);
  const [selected, setSelected] = useState<boolean[]>([] as boolean[]);
  const [changed, setChanged] = useState<boolean[]>([] as boolean[]);
  const [deleted, setDeleted] = useState<boolean[]>([] as boolean[]);
  const [userIds, setUserIds] = useState<string[][]>([] as string[][]);
  const [originalUserIds, setOriginalUserIds] = useState<string[][]>(
    [] as string[][]
  );

  const fetch = useCallback(async () => {
    if (!entityId) return;
    const roles: Role[] = await getRoles(entityId, serviceConfig);
    if (roles)
      roles.sort((a: Role, b: Role) => {
        if (a?.name > b.name) return 1;
        if (a?.name < b.name) return -1;
        return 0;
      });
    setRoles(roles);
    const userIds = roles.map((role) =>
      role.assignees ? role.assignees.map((user) => user.id) : []
    );
    setSelected(roles.map((_) => false));
    setChanged(roles.map((_) => false));
    setDeleted(roles.map((_) => false));
    setUserIds(userIds);
    setOriginalUserIds(userIds);
  }, [entityId, serviceConfig]);

  useEffect(() => {
    if (!entityId) return;
    fetch();
  }, [entityId, fetch]);

  const handleRoleSelect = (role: Role, value: boolean) => {
    if (!roles) return;
    const index = roles.findIndex(
      (existingRole) => existingRole.id === role.id
    );

    const newSelected = [...selected];
    newSelected[index] = value;
    setSelected(newSelected);
  };

  const handleAssigneeChange = (role: Role, assigneeIds: string[]) => {
    if (!roles) return;
    const index = roles.findIndex(
      (existingRole) => existingRole.id === role.id
    );
    const newUserIds = [...userIds];
    newUserIds[index] = assigneeIds;
    setUserIds(newUserIds);
    setChanged(changed.map((value, idx) => (idx === index ? true : value)));
  };

  const handleSave = () => {
    if (!entityId) return;
    if (!roles) return;

    if (!changed.some((value) => value)) {
      notify.info(translation("roleSetting.handleSave.info"));
      return;
    }

    for (let i = 0; i < roles.length; i++) {
      if (!changed[i]) continue;
      const role = roles[i];
      const assignees = userIds[i];
      updateRoleAssignees(entityId, role, assignees, serviceConfig)
        .then((role) => {
          const index = roles.findIndex((oldRole) => oldRole.id === role.id);

          setRoles((prev) => {
            const value = [...(prev || [])];
            value[index] = role;
            return value;
          });

          setUserIds((prev) => {
            const value = [...prev];
            value[index] = role.assignees.map((user) => user.id);
            return value;
          });

          setOriginalUserIds((prev) => {
            const value = [...prev];
            value[index] = role.assignees.map((user) => user.id);
            return value;
          });

          setChanged((prev) => {
            const value = [...prev];
            value[index] = false;
            return value;
          });

          notify.success(
            `${translation("roleSetting.handleSave.success")} ${role.name}`
          );
        })
        .catch((err) =>
          notify.error(
            `${translation("app.common.error")}${role.name} : ${err}`
          )
        );
    }
  };

  const handleDiscard = () => {
    setUserIds(originalUserIds);
  };

  const handleAddRole = async (form: AddRoleForm): Promise<boolean> => {
    if (!entityId) return false;
    if (!roles) return false;
    try {
      const role = await createRole(
        entityId,

        form,
        serviceConfig
      );

      setRoles([...roles, role]);
      setUserIds([...userIds, role.assignees.map((user: User) => user.id)]);
      setChanged([...changed, false]);
      setDeleted([...deleted, false]);
      setSelected([...selected, false]);
      notify.success(`${role.name} created sucessfully`);

      return true;
    } catch (e) {
      notify.error(
        `Failed adding new Role : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };

  const handleEditRole = async (
    role: Role,
    form: EditRoleForm
  ): Promise<boolean> => {
    if (!entityId) return false;
    if (!roles) return false;
    try {
      const editedRole = await editRole(
        entityId,
        role.id,
        { name: form?.name, description: form?.description },
        serviceConfig
      );
      const index = roles.findIndex((oldRole) => oldRole.id === editedRole.id);
      setRoles((prev) => {
        const value = [...(prev || [])];
        value[index] = editedRole;
        return value;
      });
      notify.success(`Role ${role.name} Updated`);

      return true;
    } catch (e) {
      notify.error(
        `Failed editing Role : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };

  const handleBatchAssign = async (form: BatchAssignForm): Promise<boolean> => {
    if (!entityId) return false;
    if (!roles) return false;

    const newRoles = [...roles];
    const newUserIds = [...userIds];
    const newOriginalUserIds = [...originalUserIds];
    const newChanged = [...changed];

    let count = 0;
    try {
      for (let i = 0; i < newRoles.length; i++) {
        if (!selected[i]) continue;
        const role = await batchAddRoleAssignees(
          entityId,
          newRoles[i],
          form.userIds,
          serviceConfig
        );
        newRoles[i] = role;
        newUserIds[i] = role.assignees.map((user) => user.id);
        newOriginalUserIds[i] = newUserIds[i];
        newChanged[i] = false;
        count++;
      }
      setRoles(newRoles);
      setUserIds(newUserIds);
      setOriginalUserIds(newOriginalUserIds);
      setChanged(newChanged);
      notify.success(`Assigned ${form.userIds.length} users to ${count} roles`);
      return true;
    } catch (e) {
      notify.error(
        `Failed adding new Role : ${e instanceof Error ? e.message : `${e}`}`
      );
      return false;
    }
  };
  const handleDelete = async (role: Role): Promise<boolean> => {
    if (!entityId) return false;
    if (!roles) return false;
    try {
      await deleteRole(entityId, role.id, serviceConfig);
      notify.success(`Role ${role.name} deleted`);

      const index = roles.findIndex(
        (existingRole) => existingRole.id === role.id
      );

      setDeleted(deleted.map((value, idx) => (idx === index ? true : value)));
      return true;
    } catch (e) {
      notify.error(
        `Delete Role ${role.name} failed : ${
          e instanceof Error ? e.message : `${e}`
        }`
      );
      return false;
    }
  };

  return (
    <RoleSettingLayout
      roles={roles}
      selected={selected}
      deleted={deleted}
      userIds={userIds}
      originalUserIds={originalUserIds}
      onRoleSelect={handleRoleSelect}
      onAssigneeChange={handleAssigneeChange}
      onDelete={handleDelete}
      onSave={handleSave}
      onDiscard={handleDiscard}
      onAddRole={handleAddRole}
      onEditRole={handleEditRole}
      onBatchAssign={handleBatchAssign}
    />
  );
};

export default RoleSettingContainer;
