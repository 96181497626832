import React, { ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IdcardFilled, MailFilled } from "@ant-design/icons";
import {
  NxpBackTop,
  NxpLayout,
  NxpNavbarConfigMenu,
  NxpSidebarProps,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";
import i18n from "i18next";

import useAppContext from "../../../hooks/useAppContext";
import notificationIcon from "../../../images/sidebar/sidebar-notification.svg";
import registerIcon from "../../../images/sidebar/sidebar-register.svg";
import reportsIcon from "../../../images/sidebar/sidebar-reports.svg";
import searchIcon from "../../../images/sidebar/sidebar-search.svg";
import securityIcon from "../../../images/sidebar/sidebar-security.svg";
import settingsIcon from "../../../images/sidebar/sidebar-settings.svg";
import { patchMyUserPreference } from "../../../services/app";

const useStyles = createUseStyles((theme) => ({
  appLayout: {
    paddingTop: -85,
    paddingLeft: -89,
  },
  search: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${searchIcon})`,
      transform: "scale(.9)",
    },
  },
  register: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${registerIcon})`,
      transform: "scale(.9)",
    },
  },
  reports: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${reportsIcon})`,
      transform: "scale(.75)",
    },
  },
  security: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${securityIcon})`,
      transform: "scale(.75)",
    },
  },
  settings: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${settingsIcon})`,
    },
  },
  notification: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${notificationIcon})`,
    },
  },
  navBar: {},
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const appContext = useAppContext();
  const {
    activeEntity,
    activeUser,
    language,
    serviceConfig,
    hasRight,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { keycloak } = useKeycloak();

  const changeLanguage = useCallback(
    async (key: string) => {
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: "entity",
        parameter: "language",
        value: key,
      });
      onAppContextCacheItemUpdate("language", key);
      return newUserPreference;
    },
    [serviceConfig, onAppContextCacheItemUpdate]
  );

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <NxpLayout
      className={classes.appLayout}
      navBarProps={{
        title: `Issue Register`,
        subtitleContent: `${activeEntity?.name || "--"} (v0.2.0)`,
        username: activeUser?.name || "",
        userPopoverContent: (
          <>
            <p className={classes.userInfo}>
              <IdcardFilled /> {activeUser?.name}
            </p>
            <p className={classes.userInfo}>
              <MailFilled /> {activeUser?.email}
            </p>
          </>
        ),
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={changeLanguage}
            onLogoutClick={() => keycloak.logout()}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={{
        items: [
          ...((activeEntity
            ? [
                hasRight("issue-read")
                  ? {
                      type: "link",
                      href: `/entities/${activeEntity.id}/issues`,
                      title: t("app.layout.Register"),
                      className: classes.register,
                    }
                  : null,

                hasRight("task-read")
                  ? {
                      type: "link",
                      href: `/entities/${activeEntity.id}/tasks`,
                      title: t("app.layout.task"),
                      className: classes.search,
                    }
                  : null,

                hasRight("role-read") || hasRight("right-read")
                  ? {
                      type: "submenu",
                      href: `/entities/${activeEntity.id}/security`,
                      title: t("app.layout.Security"),
                      className: classes.security,
                      menuLinks: [
                        ...(hasRight("role-read")
                          ? [
                              {
                                href: `/entities/${activeEntity.id}/roles`,
                                title: t("app.appLayout.manageRolesModule"),
                              },
                            ]
                          : []),
                        ...(hasRight("right-read")
                          ? [
                              {
                                href: `/entities/${activeEntity.id}/rights`,
                                title: t("app.appLayout.accessRightsModule"),
                              },
                            ]
                          : []),
                      ],
                    }
                  : null,
                hasRight("issue-type-read")
                  ? {
                      type: "link",
                      title: t("app.layout.settings"),
                      href: `/entities/${activeEntity.id}/type-config`,
                      className: classes.settings,
                    }
                  : null,
                hasRight("notification-read")
                  ? {
                      type: "link",
                      href: `/entities/${activeEntity.id}/Notification`,
                      title: t("app.layout.Notification"),
                      className: classes.notification,
                    }
                  : null,
              ]
            : []) as NxpSidebarProps["items"]),
        ],
      }}
    >
      {children}
      <NxpBackTop />
    </NxpLayout>
  );
};

export default AppLayout;
