import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  formatDate,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Col, Modal, Row } from "antd";
import { find, isObject } from "lodash";
import moment from "moment";
import * as yup from "yup";

import AppFileView from "../../../components/AppFileView";
import AppUploader from "../../../components/AppUploader";
import useAppContext from "../../../hooks/useAppContext";
import {
  createIssue,
  IssueCreateForm,
  IssueMessage,
} from "../../../services/issue";
import CustomApiError from "../../../utils/backend/customApiError";
import { notify } from "@nexploretechnology/nxp-ui";
import { toTreeSelectOptions } from "../../../utils/to-tree-select-option";
import { Action } from "../../TasksListing/TaskReview/TaskDetailModal";

interface AddIssueModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onIssueCreated?: () => void;
}

const AddIssueContainer: React.FC<AddIssueModalProps> = ({
  showModal,
  handleDialogClose,
  onIssueCreated = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [issueAttachments, setIssueAttachments] = useState<
    IssueMessage["attachments"]
  >([]);
  const appContext = useAppContext();
  const {
    issueType,
    locationGroups,
    issueCategory,
    routeParams,
    serviceConfig,
    activeUser,
  } = appContext;
  const { entityId } = routeParams;

  const confirmAction = (type: Action) =>
    new Promise((reslove) =>
      Modal.confirm({
        title: `${translation("app.common.confirmation.leave.warning.title")}`,
        icon: <ExclamationCircleOutlined />,
        okText: translation("app.common.yes"),
        cancelText: translation("app.common.no"),
        content: `${translation(
          "app.common.confirmation.leave.warning.content"
        )}`,
        onOk: async () => {
          reslove(true);
        },
        onCancel: () => {
          reslove(false);
        },
      })
    );

  useEffect(() => {
    return () => {
      setIssueAttachments([]);
    };
  }, [showModal]);
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    location: yup
      .number()
      .nullable()
      .required(translation("app.common.locationIsRequired")),
    issueType: yup
      .number()
      .nullable()
      .required(translation("app.common.issueTypeIsRequired")),
    issueCategory: yup
      .number()
      .nullable()
      .required(translation("app.common.issueCategoryIsRequired")),
    subject: yup
      .string()
      .nullable()
      .required(translation("app.common.issueSubjectIsRequired")),
    openDate: yup
      .string()
      .nullable()
      .required(translation("app.common.openDateIsRequired")),
    targetDate: yup
      .string()
      .nullable()
      .required(translation("app.common.targetDateIsRequired")),
  });

  const [issueForm, setIssueForm] = useState<Partial<IssueCreateForm>>({});
  useEffect(() => {
    return () => {
      setIssueForm({});
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, onIssueCreated]);

  const formItems: NxpFormGridItemProps<Partial<IssueCreateForm>>[] = [
    {
      controlType: "select",
      label: translation("issue.type"),
      itemFieldName: "issueType",
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: issueType.map((type) => ({
          label: type?.name,
          value: type?.id,
          disabled: !isObject(
            find(type?.coordinators, { id: activeUser?.id } as any)
          ),
        })),
      },
      required: true,
    },
    {
      controlType: "select",
      label: translation("issue.category"),
      itemFieldName: "issueCategory",
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: issueCategory.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
      },
      required: true,
    },
    {
      controlType: "input",
      label: translation("issue.subject"),
      itemFieldName: "subject",
      required: true,
      span: 24,
      startOnNewRow: true,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
    {
      controlType: "treeSelect",
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: {
          id: "#",
          selectable: false,
          checkable: false,
          isLeaf: false,
          title: translation("app.common.pleaseSelect"),
          name: translation("app.common.pleaseSelect"),
          disabled: true,
          children: [...toTreeSelectOptions(locationGroups)],
        },
      },
      label: translation("issue.location"),
      itemFieldName: "location",
      startOnNewRow: true,
      span: 12,

      required: true,
    },
    {
      controlType: "datePicker",
      label: translation("issue.createdOn"),
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        disabledDate: (current) => {
          return current < moment().startOf("day");
        },
      },
      itemFieldName: "openDate",
      startOnNewRow: true,
      required: true,
      span: 12,
    },
    {
      controlType: "datePicker",
      label: translation("issue.targetOn"),
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        disabledDate: (current) => {
          return current < moment(issueForm?.openDate).startOf("day");
        },
      },
      itemFieldName: "targetDate",
      required: true,
      span: 12,
    },
    {
      controlType: "textarea",
      label: translation("issue.description"),
      itemFieldName: "description",
      span: 24,
      startOnNewRow: true,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
  ];

  const handleSaveValidated = async () => {
    if (entityId) {
      const postObj = {
        ...issueForm,
        ...{
          location: Number(issueForm?.location),
          attachments: issueAttachments.map((file) => file?.id),
          openDate: formatDate(issueForm?.openDate),
          targetDate: formatDate(issueForm?.targetDate),
        },
      } as IssueCreateForm;
      try {
        const defect = await createIssue(entityId, postObj, serviceConfig);
        if (defect) {
          notify.success(translation("issue.addIssue.handleSaveValidated"));
          onIssueCreated();
        }
      } catch (e) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Partial<IssueCreateForm>
  >(issueForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof issueForm,
    value: unknown
  ) => {
    setIssueForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      handleDialogClose();
    }
  };
  return (
    <NxpModal
      width="large"
      title={translation("issue.add")}
      visible={showModal}
      showMandatoryLabel
      onCancel={() =>
        confirmAction("closed").then((res) => res && handleDialogClose())
      }
      footer={
        <>
          <NxpButton
            type="ghost"
            danger
            onClick={() =>
              confirmAction("closed").then((res) => res && handleDialogClose())
            }
            disabled={loading}
          >
            {translation("roleSetting.cancel")}
          </NxpButton>
          <NxpButton onClick={handleModalSave} disabled={loading}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={issueForm}
        onFormStateChange={handleFormGridStateChange}
      />
      <div className="nxpFormItem">
        <div className="ant-col ant-form-item-label">
          <label
            title="ATTACHMENT"
            style={{
              color: "rgb(138,153,153)",
              fontSize: "0.7rem",
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            {translation("issue.attachment.label", { count: 5 })}
          </label>
        </div>
      </div>

      <Row key={`issue_files`} gutter={8} align="top">
        <AppUploader
          disabled={issueAttachments.length >= 5}
          onProgessing={setLoading}
          limit={5}
          onFileUploaded={(file) => {
            setIssueAttachments((preState) => [...preState, file]);
          }}
        />
        {issueAttachments
          ?.sort((a, b) => b.id - a.id)
          .map((file) => (
            <Col key={file?.id}>
              <AppFileView
                file={file}
                onDelete={() =>
                  setIssueAttachments((preState) =>
                    preState.filter((attactment) => attactment?.id !== file?.id)
                  )
                }
              />
            </Col>
          ))}
      </Row>
    </NxpModal>
  );
};

export default AddIssueContainer;
