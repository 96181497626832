import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { CreatedTypeConfigForm } from "../../services/typeConfig";

interface Props {
  show: boolean;
  onClose: () => void;
  onAdd: (form: CreatedTypeConfigForm) => Promise<boolean>;
}

export interface AddRoleForm {
  name: string;
  code: string;
  description: string;
  userIds: number[];
}

const emptyForm = {
  name: "",
};

const AddRoleModal: React.FC<Props> = ({ show, onAdd, onClose }) => {
  const { t: translation } = useTranslation();

  const [form, setForm] = useState<CreatedTypeConfigForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("typeConfig.typeName.IsRequired")),
  });

  useEffect(() => {
    return () => {
      setForm(emptyForm);
      setSaving(false);
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const formItems: NxpFormGridItemProps<CreatedTypeConfigForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.type"),
      itemFieldName: "name",
      required: true,
      span: 24,
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onAdd(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , clearError, saveWithValidate] =
    useYupValidate<CreatedTypeConfigForm>(
      form,
      formSchema,
      handleSaveValidated
    );

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpModal
      width="large"
      title={translation("typeConfig.modal.add.title")}
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {translation("roleSetting.cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleModal;
