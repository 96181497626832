import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  formatDate,
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { IssueCreateForm } from "../../../services/issue";
import { TaskCreateForm } from "../../../services/task";

interface AddTaskModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onTaskListCreated: (form: Partial<TaskCreateForm>) => Promise<boolean>;
}

const AddTaskContainer: React.FC<AddTaskModalProps> = ({
  showModal,
  handleDialogClose,
  onTaskListCreated,
}) => {
  const appContext = useAppContext();
  const { users } = appContext;
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    assignee: yup
      .string()
      .nullable()
      .required(translation("task.assingeeIsRequired")),
    subject: yup
      .string()
      .nullable()
      .required(translation("task.subjectIsRequired")),
    openDate: yup
      .string()
      .nullable()
      .required(translation("task.opendateIsRequired")),
    targetDate: yup
      .string()
      .nullable()
      .required(translation("task.completeDateIsRequired")),
    needDocument: yup
      .boolean()
      .nullable()
      .required(translation("task.closeOutDocIsRequired")),
    documentType: yup
      .string()
      .nullable()
      .when("needDocument", {
        is: true,
        then: yup.string().required(translation("task.docTitileIsRequired")),
      }),
    description: yup.string().nullable(),
  });

  const [taskForm, setTaskForm] = useState<Partial<TaskCreateForm>>({
    openDate: formatDate(new Date()),
  });

  useEffect(() => {
    return () => {
      setTaskForm({
        openDate: formatDate(new Date()),
      });
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const formItems: NxpFormGridItemProps<Partial<TaskCreateForm>>[] = [
    {
      controlType: "input",
      label: translation("app.layout.task"),
      itemFieldName: "subject",
      required: true,
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
    {
      controlType: "select",
      label: translation("task.assigned"),
      itemFieldName: "assignee",
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: users.map((user) => ({
          label: user?.name,
          value: user?.id,
        })),
      },
      required: true,
    },
    {
      controlType: "datePicker",
      label: translation("task.opendate"),
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        disabledDate: (current) => {
          return current < moment().startOf("day");
        },
      },
      itemFieldName: "openDate",
      startOnNewRow: true,
      required: true,
      span: 12,
    },
    {
      controlType: "datePicker",
      label: translation("task.completeDate"),
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        disabledDate: (current) => {
          return current < moment(taskForm?.openDate).startOf("day");
        },
      },
      itemFieldName: "targetDate",
      required: true,
      span: 12,
    },
    {
      controlType: "radioGroup",
      label: translation("task.closeOutDoc"),
      itemFieldName: "needDocument",
      required: true,
      span: 12,
      controlProps: {
        options: [
          { label: translation("app.common.need"), value: true },
          { label: translation("app.common.not"), value: false },
        ],
      },
    },
    {
      controlType: "input",
      label: translation("task.docTitile"),
      itemFieldName: "documentType",
      required: true,
      span: 12,
      controlProps: {
        disabled: !taskForm?.needDocument,
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
    {
      controlType: "textarea",
      label: translation("task.description"),
      itemFieldName: "description",
      span: 24,
      startOnNewRow: true,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
  ];

  const handleSaveValidated = () => {
    onTaskListCreated({
      ...taskForm,
      targetDate: taskForm?.targetDate
        ? formatDate(taskForm?.targetDate)
        : undefined,
      description: taskForm?.description ? taskForm.description : "",
    } as Partial<TaskCreateForm>);
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Partial<IssueCreateForm>
  >(taskForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof taskForm,
    value: unknown
  ) => {
    if (fieldName === "openDate" || fieldName === "targetDate") {
      value = formatDate(value as string);
    }
    setTaskForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      handleDialogClose();
    }
  };
  return (
    <NxpModal
      width="large"
      title={translation("task.model.add")}
      visible={showModal}
      showMandatoryLabel
      onCancel={handleDialogClose}
      footer={
        <NxpButton onClick={handleModalSave}>
          {translation("app.common.save")}
        </NxpButton>
      }
    >
      <NxpFormGrid
        size="middle"
        validationError={validationError}
        formItems={formItems}
        formState={taskForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddTaskContainer;
