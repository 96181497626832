import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { uniq } from "lodash";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { NotificationForm } from "../../../services/notification";

interface AddIssueModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onIssueNotificationCreated: (
    form: Partial<NotificationForm>
  ) => Promise<boolean>;
}

const AddIssueNotificationContainer: React.FC<AddIssueModalProps> = ({
  showModal,
  handleDialogClose,
  onIssueNotificationCreated,
}) => {
  const [issueForm, setIssueForm] = useState<Partial<NotificationForm>>({});
  const appContext = useAppContext();
  const { routeParams, users, media } = appContext;
  const { entityId } = routeParams;
  useEffect(() => {
    return () => {
      setIssueForm({});
    };
  }, [showModal]);
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    media: yup
      .string()
      .nullable()
      .required(translation("notification.channelIsRequired")),
    recipients: yup
      .array()
      .nullable()
      .required(translation("notification.channelIsRequired")),
    message: yup
      .string()
      .nullable()
      .required(translation("notification.destinationIsRequired")),
  });

  useEffect(() => {
    // if (showModal) {
    // 	if (_.isEmpty(issueForm?.)) {
    // 		handleFormGridStateChange("isTopPriority", "false");
    // 		setissueForm((form) => {
    // 			const result = { ...form };
    // 			if (locationId) {
    // 				result.location = "" + locationId;
    // 			}
    // 			setDate("dueDate", "dueDate");
    // 			setDate("nextInspectionDate", "nextInspectionDate");
    // 			return result;
    // 		});
    // 	}
    // }
    return () => {
      clearError();
      setIssueForm({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // activeEntityParameter?.dueDate,
    // activeEntityParameter?.nextInspectionDate,
    // locationId,
    showModal,
    onIssueNotificationCreated,
  ]);
  const formItems: NxpFormGridItemProps<Partial<NotificationForm>>[] = [
    {
      controlType: "select",
      label: translation("notification.channel"),
      itemFieldName: "media",
      required: true,
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: uniq(media).map((channel, index) => {
          return {
            label: translation(`app.common.${channel}`),
            value: channel,
            disabled: channel === "email" ? false : true,
          };
        }),
      },
    },
    {
      controlType: "selectMultiple",
      label: translation("notification.destination"),
      itemFieldName: "recipients",
      required: true,
      span: 12,
      controlProps: {
        placeholder: translation("app.common.placeholder.select"),
        allowClear: true,
        options: users.map((user) => ({
          label: user?.name,
          value: user?.id,
        })),
      },
    },
    {
      controlType: "textarea",
      label: translation("notification.description"),
      itemFieldName: "message",
      required: true,
      span: 24,
      startOnNewRow: true,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
  ];

  const handleSaveValidated = async () => {
    if (entityId) {
      onIssueNotificationCreated(issueForm);
    }
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Partial<NotificationForm>
  >(issueForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof issueForm,
    value: unknown
  ) => {
    setIssueForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      handleDialogClose();
    }
  };
  return (
    <NxpModal
      width="medium"
      title={translation("notification.sendNew")}
      visible={showModal}
      showMandatoryLabel
      onCancel={handleDialogClose}
      footer={
        <NxpButton onClick={handleModalSave}>
          {translation("notification.send")}
        </NxpButton>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={issueForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddIssueNotificationContainer;
