import "../../i18n/i18n";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ThemeProvider } from "react-jss";

import AppContextProvider from "./AppContext/AppContextProvider";
import AppRouter from "./AppRouter";
import getKeycloakInstance from "./keycloak";
import { ConfigProvider as AntDesignConfigProvider } from "antd";
import { appTheme } from "../../utils/appTheme";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const tokenLogger = (tokens: unknown) => {
    console.log("onKeycloakTokens", tokens);
  };

  return (
    <ReactKeycloakProvider
      authClient={getKeycloakInstance()}
      // onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: "login-required",
        flow: "implicit",
      }}
    >
      <ThemeProvider theme={appTheme}>
        <AntDesignConfigProvider
          theme={{
            token: {
              colorPrimary: appTheme.palette.primary,
              colorLink: appTheme.palette.textLink,
              colorSuccess: appTheme.palette.success,
              colorWarning: appTheme.palette.warning,
              colorError: appTheme.palette.danger,
              colorHighlight: appTheme.palette.warning,
              fontSize: appTheme.fontSize.main,
              colorTextHeading: appTheme.palette.text,
              colorText: appTheme.palette.text,
              colorTextSecondary: "rgba(0, 0, 0, 0.45)",
              colorTextDisabled: "rgba(0, 0, 0, 0.25)",
              colorBgContainerDisabled: appTheme.palette.dimLight,
              borderRadius: appTheme.borderRadius.main,
              colorBorder: appTheme.palette.border,
              boxShadow:
                "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)", // major shadow for layers
              // cannot find relevant setting after upgrade
              // "@badge-font-size-sm": "11px",
              // "@badge-color": appTheme.palette.primary,
            },
          }}
        >
          <AppContextProvider>
            <AppRouter />
          </AppContextProvider>
        </AntDesignConfigProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
