import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import {
  getEntityInfo,
  getLocationGroups,
  getMe,
  getUsers,
  getUserRightList,
  getMyUserPreference,
  createMyUserPreference,
} from "../../../services/app";
import { notify } from "@nexploretechnology/nxp-ui";
import { useTranslation } from "react-i18next";
import {
  getIssueCategories,
  getIssueTypes,
  IssueCategory,
} from "../../../services/issue";
import { IssueType } from "../../../services/typeConfig";

// for setting cache data in app context based on route param
const useRouteAppContextUpdate = () => {
  const { keycloak } = useKeycloak();
  const { t: translation } = useTranslation();
  const appContext = useAppContext();
  const loadingEntityIdRef = useRef("");
  const [loadingEntityId, setLoadingEntityId] = useState<string>("");
  const loadingLanguage = useRef(false);
  let routeParamsChanged = false;

  const { entityId } = useParams<"entityId">();
  const { issueId: issueIdStr } = useParams<"issueId">();

  const issueId = Number.isFinite(Number(issueIdStr))
    ? Number(issueIdStr)
    : undefined;

  if (entityId !== appContext?.routeParams.entityId) {
    routeParamsChanged = true;
  }

  if (issueId !== appContext?.routeParams.issueId) {
    routeParamsChanged = true;
  }

  if (routeParamsChanged) {
    appContext?.onAppContextCacheItemUpdate("routeParams", {
      entityId,
      issueId,
    });
  }

  if (!appContext.serviceConfig.token) return;

  if (entityId && keycloak) {
    if (appContext.activeUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(appContext.serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === "entity" && u.parameter === "language"
        );
        if (languagePreference) {
          appContext.onAppContextCacheItemUpdate(
            "language",
            languagePreference.value
          );
        } else {
          createMyUserPreference(appContext.serviceConfig, {
            application: "entity",
            parameter: "language",
            value: "en",
          }).then((newUserPreference) => {
            appContext.onAppContextCacheItemUpdate(
              "language",
              newUserPreference.value
            );
          });
        }
      });
    }

    if (
      appContext.activeEntity?.id !== entityId &&
      loadingEntityId !== entityId
    ) {
      loadingEntityIdRef.current = entityId;
      setLoadingEntityId(entityId);

      getEntityInfo(entityId, appContext.serviceConfig)
        .then((entity) => {
          appContext.onAppContextCacheItemUpdate("activeEntity", entity);
        })
        .catch((e) =>
          notify.error(
            `${translation("app.common.error")} ${entityId} : ${e.message}`
          )
        );

      getMe(entityId, appContext.serviceConfig)
        .then((user) => {
          appContext.onAppContextCacheItemUpdate("activeUser", user);
        })
        .catch((e) =>
          notify.error(
            `${translation("app.common.error")} ${entityId} : ${e.message}`
          )
        );
      getUserRightList(entityId, appContext.serviceConfig)
        .then((userRightList) => {
          appContext.onAppContextCacheItemUpdate(
            "userRightList",
            userRightList
          );
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getUserRightList.error")}: ${e.message}`
          )
        );
      getUsers(entityId, appContext.serviceConfig)
        .then((users) => {
          appContext.onAppContextCacheItemUpdate("users", users);
        })
        .catch((e) =>
          notify.error(
            `${translation("app.common.error")} ${entityId} : ${e.message}`
          )
        );

      getLocationGroups(entityId, appContext.serviceConfig)
        .then((locationGroups) => {
          appContext.onAppContextCacheItemUpdate(
            "locationGroups",
            locationGroups
          );
        })
        .catch((e) =>
          notify.error(
            `${translation(
              "appRouter.getLocationGroups.error"
            )} ${entityId} : ${e.message}`
          )
        );

      getIssueTypes(entityId, appContext.serviceConfig)
        .then((enums: IssueType[]) => {
          appContext.onAppContextCacheItemUpdate("issueType", enums);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getEnums.error")} ${entityId} : ${
              e.message
            }`
          )
        );
      getIssueCategories(entityId, appContext.serviceConfig)
        .then((issueCategory: IssueCategory[]) => {
          appContext.onAppContextCacheItemUpdate(
            "issueCategory",
            issueCategory
          );
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getEnums.error")} ${entityId} : ${
              e.message
            }`
          )
        );
    }
  }
};

export default useRouteAppContextUpdate;
