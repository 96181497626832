import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  useYupValidate,
} from "@nexploretechnology/nxp-ui";
import { Card, Col, Row } from "antd";
import clsx from "clsx";
import * as yup from "yup";

import AppFileView from "../../../components/AppFileView";
import AppUploader from "../../../components/AppUploader";
import useAppContext from "../../../hooks/useAppContext";
import { IssueMessage } from "../../../services/issue";
import { TaskResponseFrom } from "../../../services/task";
import CustomApiError from "../../../utils/backend/customApiError";
import { notify } from "@nexploretechnology/nxp-ui";
import { TaskDetailsContext } from "../TaskListing";

interface AddIssueModalProps {
  setAttactmentLoading: (toggle: boolean) => void;
}
const useStyles = createUseStyles((theme) => ({
  inputLable: {
    "& label": {
      color: theme.palette.textLabel,
      fontSize: theme.pxToRem(12),
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: theme.spacing(0),
      "& ::after": {
        content: "",
      },
    },
  },
}));

const AddTaskContainer: React.FC<AddIssueModalProps> = ({
  setAttactmentLoading,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [issueAttachments, setIssueAttachments] = useState<
    IssueMessage["attachments"]
  >([]);
  const { taskId, onTaskResponseCreated, onFetchOneTask } =
    useContext(TaskDetailsContext);
  const appContext = useAppContext();
  const { routeParams } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();
  useEffect(() => {
    setAttactmentLoading(loading);
  }, [loading, setAttactmentLoading]);

  const formSchema = yup.object().shape({
    comment: yup
      .string()
      .nullable()
      .required(translation("task.comment.isRequired")),
  });

  const [resposeForm, setResponseForm] = useState<Partial<TaskResponseFrom>>(
    {}
  );

  useEffect(() => {
    return () => {
      setResponseForm({} as TaskResponseFrom);
      clearError();
      setIssueAttachments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const formItems: NxpFormGridItemProps<Partial<TaskResponseFrom>>[] = [
    {
      controlType: "textarea",
      label: translation("task.comment.label"),
      itemFieldName: "comment",
      span: 24,
      startOnNewRow: true,
      required: true,
      controlProps: {
        placeholder: translation("app.common.placeholder.enter"),
        allowClear: true,
      },
    },
  ];

  const handleSaveValidated = async () => {
    if (entityId && resposeForm) {
      const postObj = {
        ...resposeForm,
        ...{
          documents: issueAttachments.map((file) => file?.id),
        },
      } as any;
      try {
        const res = await onTaskResponseCreated(taskId, postObj);
        if (res) {
          setIssueAttachments([]);
          setResponseForm({});
          onFetchOneTask(taskId);
        }
      } catch (e) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Partial<TaskResponseFrom>
  >(resposeForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof resposeForm,
    value: unknown
  ) => {
    setResponseForm(
      (prevState) =>
        prevState && {
          ...prevState,
          [fieldName]: value,
        }
    );
  };

  const handleModalSave = () => {
    if (handleSave()) {
      setIssueAttachments([]);
    }
  };
  return (
    <>
      <Card
        title={translation("tack.response")}
        extra={
          <NxpButton onClick={handleModalSave} disabled={loading}>
            {translation("task.add.response")}
          </NxpButton>
        }
      >
        <NxpFormGrid
          validationError={validationError}
          formItems={formItems}
          formState={resposeForm}
          onFormStateChange={handleFormGridStateChange}
        />
        <div
          className={clsx(classes.inputLable, "ant-col ant-form-item-label")}
        >
          <div className="ant-form-item">
            <label title="Attachment">{translation("task.attachment")}</label>
          </div>
        </div>
        <Row key={`issue_files`} gutter={8} align="top">
          <AppUploader
            disabled={issueAttachments.length >= 5}
            onProgessing={setLoading}
            onFileUploaded={(file) => {
              setIssueAttachments((preState) => [...preState, file]);
            }}
          />

          {issueAttachments
            ?.sort((a, b) => b.id - a.id)
            .map((file) => (
              <Col>
                <AppFileView
                  file={file}
                  onDelete={() =>
                    setIssueAttachments((preState) =>
                      preState.filter(
                        (attactment) => attactment?.id !== file?.id
                      )
                    )
                  }
                />
              </Col>
            ))}
        </Row>
      </Card>
    </>
  );
};

export default AddTaskContainer;
