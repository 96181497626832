import { Location } from "../services/app";

export const toTreeSelectOptions = (
  array: Location[]
): { id: number; name: string; children: any[] }[] => {
  const childrenDict: Record<number, number[]> = {};
  const locationById: Record<number, Location> = {};

  for (const location of array) {
    locationById[location.id] = location;

    const parentId = location.parent?.id || 0;
    if (!childrenDict.hasOwnProperty(parentId)) {
      childrenDict[parentId] = [];
    }
    childrenDict[parentId].push(location.id);
  }
  const recursion = (
    locationIds: number[]
  ): { id: number; name: string; children: any[] }[] => {
    if (!locationIds) return [];

    return locationIds.map((id) => {
      const location = locationById[id];
      return {
        id: location.id,
        name: location.name,
        children: recursion(childrenDict[id]),
        title: location.name,
      };
    });
  };
  return recursion(childrenDict[0]);
};
