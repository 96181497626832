import React from "react";

import {
  Entity,
  User,
  UserRightList,
  Location,
  Media,
} from "../../../services/app";
import { IssueCategory } from "../../../services/issue";
import { IssueType } from "../../../services/typeConfig";
import { ServiceConfig } from "../../../utils/backend";

export interface AppRouteParams {
  entityId?: string;
  issueId?: number;
}

export interface AppContextCache {
  users: User[];
  activeEntity?: Entity;
  activeUser?: User;
  userRightList?: UserRightList;
  issueType: IssueType[];
  issueCategory: IssueCategory[];
  locationGroups: Location[];
  locationFullPaths: Record<number, string>;
  routeParams: AppRouteParams;
  media: Media[];
  language?: string;
}

type AppContextType = AppContextCache & {
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  refetchIssueTypes: (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ) => Promise<IssueType[] | undefined>;
  refetchIssueCategories: (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ) => void;
  hasRight: (rightType: keyof UserRightList, source?: "module") => boolean;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
