import React, { SetStateAction, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormItem,
  NxpTableEditable,
  useYupValidate,
  ValidationResult,
} from "@nexploretechnology/nxp-ui";
import { Empty } from "antd";
import { ColumnProps } from "antd/lib/table";
import { sortBy } from "lodash";
// import { ValidationResult } from "../../hooks/useValidate";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { IssueDocuments } from "../../services/issue";
import CustomApiError from "../../utils/backend/customApiError";
import { notify } from "@nexploretechnology/nxp-ui";

interface Props {
  onDocumentEditSubmit: (form: IssueDocuments) => Promise<boolean>;
  onDocumentUpdateSubmit: (form: IssueDocuments) => Promise<boolean>;
  onDocumentDeleteSubmit: (form: IssueDocuments) => Promise<boolean>;
  IssueDocuments: IssueDocuments[];
  loading: boolean;
  editable: boolean;
}

const AppIssueDocument: React.FC<Props> = ({
  IssueDocuments,
  onDocumentEditSubmit,
  onDocumentUpdateSubmit,
  onDocumentDeleteSubmit,
  loading,
}) => {
  const { t: translation } = useTranslation();
  const AppContext = useAppContext();
  const { routeParams } = AppContext;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getColumns = (
    editRecord: IssueDocuments | undefined,
    setEditRecord: (
      callback: SetStateAction<IssueDocuments | undefined>
    ) => void,
    validationError: ValidationResult<IssueDocuments>
  ): ColumnProps<IssueDocuments>[] => [
    {
      title: translation("issue.relatedDocument.title"),
      dataIndex: "title",
      width: 450,
      render: (_: unknown, record: IssueDocuments) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.title}
            controlProps={{
              value: editing ? editRecord?.title : record.title,
              onChange: (e) =>
                setEditRecord(
                  (prevState) =>
                    prevState && { ...prevState, title: e.target.value }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("issue.relatedDocument.documentNumber"),
      dataIndex: "documentNumber",
      width: 450,
      render: (_: unknown, record: IssueDocuments) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError?.documentNumber}
            controlProps={{
              value: editing
                ? editRecord?.documentNumber
                : record.documentNumber,
              onChange: (e) =>
                setEditRecord(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      documentNumber: e.target.value,
                    }
                ),
            }}
          />
        );
      },
    },
  ];
  const formSchema = yup.object({
    title: yup
      .string()
      .nullable()
      .required(translation("issue.relatedDocument.titleIsRequired")),
    documentNumber: yup
      .string()
      .nullable()
      .required(translation("issue.relatedDocument.documentNumberIsRequired")),
  });
  const [editItem, setEditItem] = useState<IssueDocuments>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const handleSaveValidated = async () => {
    if (!editItem) return;
    setSaveInProgress(true);
    if (routeParams?.entityId && routeParams?.issueId) {
      try {
        const success = await onDocumentEditSubmit(editItem);
        if (success) {
          setEditItem(undefined);
          setSaveInProgress(false);
        }
      } catch (e: any) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };
  const [validationError, , clearError, saveWithValidate] = useYupValidate<
    Partial<IssueDocuments>
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    [editItem, getColumns, validationError]
  );
  const handleRowEdit = useCallback(
    (editItem: IssueDocuments) => setEditItem({ ...editItem }),
    []
  );
  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );
  let documentsData: IssueDocuments[] = sortBy(IssueDocuments, ["id"]);

  const handleRowDelete = useCallback((deleteItem: IssueDocuments) => {
    setSaveInProgress(true);
    onDocumentDeleteSubmit(deleteItem);
    setEditItem(undefined);
    setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NxpTableEditable
        locale={{
          emptyText: (
            <>
              <Empty description={translation("app.common.noData")}></Empty>
            </>
          ),
        }}
        editItem={editItem}
        saveInProgress={saveInProgress}
        // validationError={validationError as ValidationResult<Inspection>}
        itemCompareKey="id"
        onRowEdit={handleRowEdit}
        onRowSave={handleRowSave}
        onRowCancel={handleRowCancel}
        onRowDelete={handleRowDelete}
        columns={columns as any} //any for fixing antd type error
        dataSource={documentsData as any}
        pagination={false}
        loading={loading}
      />
    </>
  );
};

export default AppIssueDocument;
