import { AddRoleForm } from "../pages/RoleSetting/AddRoleModal";
import { EditRoleForm } from "../pages/RoleSetting/EditRoleModal";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { User, UserRightList } from "./app";

export interface Role {
  id: number;
  entityId: string;
  code: string;
  name: string;
  description: string;
  assignees: User[];
  rights: Right[];
}

export interface Right {
  id: number;
  type: keyof UserRightList;
  role: Role;
}

export const getRoles = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles`;

  return await apiRequest<Role[]>(url, serviceConfig);
};

export const updateRoleAssignees = async (
  entityId: string,
  role: Role,
  userIds: string[],
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles/${role.id}/assignees`;

  return await apiRequest<Role>(url, serviceConfig, {
    method: "PUT",
    body: JSON.stringify({ userIds }),
  });
};

export const batchAddRoleAssignees = async (
  entityId: string,

  role: Role,
  userIds: number[],
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles/${role.id}/assignees`;

  return await apiRequest<Role>(url, serviceConfig, {
    method: "PATCH",
    body: JSON.stringify({ userIds }),
  });
};

export const updateRoleRights = async (
  entityId: string,
  locationGroupId: number | undefined,
  role: Role,
  rightTypes: keyof UserRightList[],
  serviceConfig: ServiceConfig
) => {
  const url = locationGroupId
    ? `/entities/${entityId}/location-groups/${locationGroupId}/roles/${role.id}/rights`
    : `/entities/${entityId}/roles/${role.id}/rights`;

  return await apiRequest<Role>(url, serviceConfig, {
    method: "PUT",
    body: JSON.stringify({ rightTypes }),
  });
};

export const deleteRole = async (
  entityId: string,
  roleId: number,
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles/${roleId}`;

  return await apiRequest(url, serviceConfig, { method: "DELETE" });
};

export const createRole = async (
  entityId: string,
  form: AddRoleForm,
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles`;

  return await apiRequest(url, serviceConfig, {
    method: "POST",
    body: JSON.stringify(form),
  });
};

export const editRole = async (
  entityId: string,
  roleId: number,
  form: EditRoleForm,
  serviceConfig: ServiceConfig
) => {
  const url = `/entities/${entityId}/roles/${roleId}`;

  return await apiRequest(url, serviceConfig, {
    method: "PATCH",
    body: JSON.stringify(form),
  });
};
