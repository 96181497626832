import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";

import {
  DateFormatPattern,
  NxpButton,
  NxpDateDisplay,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Card, Col, Divider, Row } from "antd";
import { Key } from "antd/lib/table/interface";
import clsx from "clsx";
import { get } from "lodash";

import AppFileList from "../../components/AppFileList";
import AppIssueDocument from "../../components/AppIssueDocument";
import AppIssueImplication from "../../components/AppIssueImplication";
import AppIssueNotification from "../../components/AppIssueNotification";
import useAppContext from "../../hooks/useAppContext";
import { File } from "../../services/app";
import { Issue } from "../../services/issue";
import themeConfig from "../../theme.config";
import TaskListing from "../TasksListing";
import AddIssueAttachhmentButton from "./AddAttachment/AddDocumentButton";
import AddIssueDocumentButton from "./AddDocument/AddDocumentButton";
import AddIssueNotificationButton from "./AddNotification/AddNotificationButton";
import { IssueDetailsContext, IssueTab } from "./IssuesDetailsContainer";

const useStyles = createUseStyles((theme) => ({
  IssueDetailsLayout: {
    "& .defect-summary-wrapper": {
      display: "flex",
      flexDirection: "column",

      "&>.ant-collapse": {
        marginBottom: theme.pxToRem(16),
      },
    },
    "& .ant-tabs-tabpane.summary": {
      "& .ant-collapse": {
        marginBottom: theme.spacing(2),
      },
    },
  },
  issueItemInfo: {
    marginBottom: theme.spacing(2),
    background: theme.palette.backgroundLight,
    "& .ant-card-head-title": {
      width: "100%",
      fontWeight: theme.fontWeight.bolder,
      fontSize: theme.pxToRem(16),
    },
    "& .issue-status": {
      fontWeight: theme.fontWeight.bolder,
      textTransform: "uppercase",
    },
    "& .closed, & .overdue": {
      color: theme.palette.warning,
    },
    "& .open": {
      color: theme.palette.primary,
    },
    "& .ant-card-body": {
      padding: theme.spacing(1),
    },
    "& .summary-info-col": {
      margin: theme.spacing(2, 0),
    },
  },
  container: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .outer": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%;",
      padding: theme.spacing(1),
      "& .inner": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
      },
    },
    "& .flexible": {
      flexGrow: 1,
    },
  },
  issueCard: {
    width: "100%",
    border: "none",
    // height: 200,
    "& h1": {
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
}));

interface IssueDetailsLayoutProps {
  issue: Issue;
}

const IssueDetailsLayout: React.FC<IssueDetailsLayoutProps> = ({ issue }) => {
  const classes = useStyles();
  const editable = !["closed"].includes(issue.status);
  const navigate = useNavigate();
  const [deleteList, setDeleteList] = useState<Key[]>([]);
  const { hasRight, activeUser } = useAppContext();
  const { t: translation } = useTranslation();

  const {
    setActiveTab,
    onIssueImplicationSubmit,
    onDocumentEditSubmit,
    loading,
    onDocumentCreated,
    onDocumentDeleted,
    onDocumentUpdated,
    onAttachmentCreated,
    onAttachmentDeleted,
    onIssueCloseOutSubmit,
    onNotificationCreated,
  } = useContext(IssueDetailsContext);
  return (
    <div className={classes.IssueDetailsLayout}>
      <NxpHeader
        onBackClick={() => navigate(-1)}
        titleContent={`${translation("app.common.issue")} : ${issue?.code}`}
        actionContent={
          <>
            {/* handlePrint */}
            {hasRight("issue-manage") && (
              <NxpButton
                type="primary"
                disabled={
                  !(issue?.totalTaskCount - issue?.closedTaskCount === 0) ||
                  issue?.status === "closed"
                }
                onClick={() => onIssueCloseOutSubmit()}
              >
                {translation("issue.closeBtn")}
              </NxpButton>
            )}
          </>
        }
      />
      <Card
        title={translation("issue.detail.title")}
        className={classes.issueItemInfo}
        extra={
          <span className={clsx("issue-status", issue?.status)}>
            {translation(`app.common.${issue?.status}`)}
          </span>
        }
      >
        <Row key={`issueItem-wrapper-${issue?.id}`}>
          <Col span={24} className="summary-info-col">
            <div className={classes.container} key={`issue${issue?.id}`}>
              <div className="outer">
                <div className="inner">
                  {[
                    {
                      lable: translation("issue.type"),
                      value: get(issue, "issueType.name"),
                    },
                    {
                      lable: translation("issue.category"),
                      value: get(issue, "issueCategory.name"),
                    },

                    {
                      lable: translation("issue.location"),
                      value: get(issue, "location.fullPath"),
                    },
                    {
                      lable: translation("issue.subject"),
                      value: get(issue, "subject"),
                    },
                  ].map((item, index) => (
                    <Col
                      span={6}
                      key={`summary_${index}`}
                      style={{
                        borderLeft: index
                          ? `1px solid ${themeConfig.palette.borderLight}`
                          : "null",
                      }}
                    >
                      <Card size="small" className={classes.issueCard}>
                        <p>{item.lable}</p>
                        <div>{item.value}</div>
                      </Card>
                    </Col>
                  ))}
                </div>
                <Divider />
                <div className="inner">
                  {[
                    {
                      type: "date",
                      lable: translation("issue.createdOn"),
                      value: get(issue, "openDate"),
                    },
                    {
                      type: "date",
                      lable: translation("issue.targetOn"),
                      value: get(issue, "targetDate"),
                    },

                    {
                      type: "date",
                      lable: translation("issue.closeOn"),
                      value: get(issue, "closeOn", ""),
                    },
                    {
                      lable: translation("issue.description"),
                      value: get(issue, "description"),
                    },
                  ].map((item, index) => (
                    <Col
                      span={6}
                      key={`summary_${index}`}
                      style={{
                        borderLeft: index
                          ? `1px solid ${themeConfig.palette.borderLight}`
                          : "",
                      }}
                    >
                      {get(item, "type", false) ? (
                        <Card size="small" className={classes.issueCard}>
                          <p>{item.lable}</p>
                          <NxpDateDisplay
                            date={item.value as unknown as Date}
                            format={DateFormatPattern.date}
                          />
                        </Card>
                      ) : (
                        <Card size="small" className={classes.issueCard}>
                          <p>{item.lable}</p>
                          <div>{item.value}</div>
                        </Card>
                      )}
                    </Col>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      <NxpTabs
        defaultActiveKey={"actions" as IssueTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane
          tab={translation("issue.detail.action")}
          key={"actions" as IssueTab}
          className="actions"
        >
          <Card size="default" title={translation("task.subject")}>
            <TaskListing
              status={issue?.status}
              owner={get(issue, "creator.id")?.toString() === activeUser?.id}
            />
          </Card>
        </TabPane>
        <TabPane
          tab={translation("issue.detail.implication")}
          key={"implications" as IssueTab}
          className={"implications" as IssueTab}
        >
          <AppIssueImplication
            handleIssueImplicationSave={onIssueImplicationSubmit}
            status={issue?.status}
            implication={{
              cost: issue?.cost,
              reputation: issue?.reputation,
              time: issue?.time,
              commercial: issue?.commercial,
              implicationDescription: issue?.implicationDescription,
            }}
          />
        </TabPane>
        <TabPane
          tab={translation("issue.detail.relatedDoc")}
          key={"relatedDoc" as IssueTab}
          className={"relatedDoc" as IssueTab}
        >
          <Card
            size="default"
            title={translation("app.common.details")}
            extra={
              hasRight("issue-manage") && (
                <AddIssueDocumentButton
                  onDocumentCreated={onDocumentCreated}
                  status={issue?.status}
                />
              )
            }
          >
            <AppIssueDocument
              onDocumentEditSubmit={onDocumentEditSubmit}
              onDocumentDeleteSubmit={onDocumentDeleted}
              onDocumentUpdateSubmit={onDocumentUpdated}
              IssueDocuments={issue?.issueDocuments}
              loading={loading}
              editable={editable}
            />
          </Card>
        </TabPane>
        <TabPane
          tab={translation("issue.detail.notification", {
            count: issue?.notifications.length,
          })}
          key={"notification" as IssueTab}
          className={"notification" as IssueTab}
        >
          <Card
            size="default"
            title={translation("app.common.details")}
            extra={
              <>
                {hasRight("notification-create") && (
                  <AddIssueNotificationButton
                    status={issue?.status}
                    onNotificationreated={onNotificationCreated}
                  />
                )}
              </>
            }
          >
            <AppIssueNotification issueNotifications={issue?.notifications} />
          </Card>
        </TabPane>
        <TabPane
          tab={translation("issue.detail.attachments", {
            count: issue?.attachments?.length,
          })}
          key={"attachment" as IssueTab}
          className={"attachment" as IssueTab}
        >
          <Card
            size="default"
            title={`${translation("app.common.details")}`}
            extra={
              <>
                {hasRight("issue-manage") && (
                  <>
                    {!deleteList.length ? (
                      <AddIssueAttachhmentButton
                        status={issue?.status}
                        onAttachmentCreated={onAttachmentCreated}
                      />
                    ) : (
                      <NxpButton
                        type="primary"
                        danger
                        onClick={() =>
                          onAttachmentDeleted(deleteList).then(() =>
                            setDeleteList([])
                          )
                        }
                      >
                        {translation("app.common.delete")}
                      </NxpButton>
                    )}
                  </>
                )}
              </>
            }
          >
            <AppFileList
              fileList={issue?.attachments as File[]}
              setIssueList={setDeleteList}
              status={issue?.status}
            />
          </Card>
        </TabPane>
      </NxpTabs>
    </div>
  );
};

export default IssueDetailsLayout;
